<div class="hidden z-10 fixed right-3 top-[30%] lg:flex flex-col cursor-pointer p-2 space-y-3 bg-white rounded-lg border">
    <a href="https://zalo.me/rausachtrangia"><img loading="lazy"class="hover:scale-[110%] duration-500" src="/assets/images/zalo_icon.png"  width="40"></a>
    <a href="https://www.facebook.com/rausachtrangia2011"><img loading="lazy"class="hover:scale-[110%] duration-500" src="/assets/images/facebook_icon.png"  width="40"></a>
    <a href="tel:0865770009"><img loading="lazy"class="hover:scale-[110%] duration-500" src="/assets/images/phone_icon.png"  width="40"></a>
</div>


<div class="lg:hidden z-10 -bottom-[2px] right-0 w-full fixed flex flex-row cursor-pointer p-4 justify-center space-x-4 bg-white border">
    <a href="https://zalo.me/rausachtrangia"><img loading="lazy"class="hover:scale-[110%] duration-500" src="/assets/images/zalo_icon.png"  width="40"></a>
    <a href="https://www.facebook.com/rausachtrangia2011"><img loading="lazy"class="hover:scale-[110%] duration-500" src="/assets/images/facebook_icon.png"  width="40"></a>
    <a href="tel:0865770009"><img loading="lazy"class="hover:scale-[110%] duration-500" src="/assets/images/phone_icon.png"  width="40"></a>
</div>