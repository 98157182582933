<div class="w-screen h-screen flex flex-col bg-gray-100">
  <div class="h-16">
    <div class="flex flex-row space-x-2 justify-between items-center py-2 px-4 border-b h-16">
      <div class="flex flex-row space-x-4 items-center">
        <button mat-icon-button><span class="material-symbols-outlined" (click)="drawer.toggle()">menu</span></button>
       <a href="/"><img loading="lazy" src="/assets/images/logo.webp" class="h-12" /></a>
        <div class="relative mt-1 lg:w-80">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
          </div>
          <input type="text" name="email" id="topbar-search" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-2 focus:ring-fuchsia-50 focus:border-fuchsia-300 block w-full pl-10 p-2.5" placeholder="Tìm Kiếm">
          </div>
        <!-- <div class="w-full text-xs lg:flex hidden">
          <mat-form-field appearance="outline">
            <mat-label>Tìm Kiếm</mat-label>
            <input matInput (keyup)="applyFilter($event)" class="w-full" placeholder="Tìm Kiếm">
            <span matPrefix class="material-symbols-outlined mr-2">search</span>
          </mat-form-field>
        </div> -->
      </div>
      <div class="flex flex-row space-x-4 items-center">
        <!-- <app-notifications></app-notifications>
        <app-chatrealtime></app-chatrealtime> -->
        <app-profile></app-profile>
        <!-- <a href="/">
          <button mat-icon-button>
              <span class="material-symbols-outlined">
                captive_portal
              </span>
          </button>
         </a> -->
      </div>
    </div>
  </div>
  <mat-drawer-container class="example-container w-full h-full" autosize>
    <mat-drawer #drawer [mode]="DrawerMode" [opened]="isOpen" class="!bg-gray-100 !flex flex-col flex-auto min-w-0 relative h-full"
      position="start">
      <div class="relative flex flex-col h-full w-52 overflow-auto">
        <div class="relative flex flex-col h-full p-4 w-full overflow-auto">
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="!bg-gray-100">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
              <a
                class="cursor-pointer flex flex-row space-x-2 justify-between p-3 w-full rounded-lg hover:bg-gray-200 hover:font-bold"
                [href]="'admin/'+[node.Slug]" routerLinkActive="!bg-gray-200 !font-bold" (click)="ChoosenMenu()">
                <span>
                  {{node.Title}}
                </span>
              </a>
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding class="pl-2">
              <div
              routerLinkActive="!bg-gray-200 !font-bold"
                class="cursor-pointer flex flex-row space-x-2 items-center justify-between p-3 w-full rounded-lg hover:bg-gray-100 hover:font-bold">
                <span>
                  {{node.Title}}
                </span>
                <span mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.Title">
                  <span
                    class="material-symbols-outlined">{{treeControl.isExpanded(node)?'expand_more':'navigate_next'}}</span>
                </span>
              </div>
            </mat-tree-node>
          </mat-tree>
        </div>
        <div class="relative flex flex-0 items-center p-4 border-t">Rau Sạch Trần Gia &copy; {{ Today| date:'yyyy' }}</div>

      </div>
    </mat-drawer>
    <mat-drawer-content class="!flex flex-col flex-auto min-w-0 relative bg-gray-100">
      <div class="relative flex flex-col h-full w-full overflow-auto">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>