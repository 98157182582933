<mat-drawer-container class="example-container w-full h-full " autosize>
  <mat-drawer #drawer mode="side" class="!flex flex-col flex-auto min-w-0 relative h-full w-full" position="end">
    <div class="relative flex flex-col h-full w-full overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer>
  <mat-drawer-content class="!flex flex-col flex-auto min-w-0 relative !h-[90vh]">
    <div class="relative flex flex-col">
      <div class="flex justify-between items-center space-x-2 m-2 bg-white p-3 rounded-lg">
        <div class="flex flex-row space-x-3 justify-center items-center">
          <div class="relative mt-1 lg:w-80">
            <input (keyup)="applyFilter($event)" type="text" name="email" id="topbar-search"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-2 focus:ring-fuchsia-50 focus:border-fuchsia-300 block w-full p-2.5"
              placeholder="Tìm Kiếm">
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <div class="flex flex-row space-x-2 items-center justify-center">
                <span class="material-symbols-outlined text-xl" matTooltip="Tùy Chỉnh">tune</span>
                <span class="text-sm">Cấu Hình</span>
              </div>
            </button>
            <button mat-menu-item>
              <div class="flex flex-row space-x-2 items-center justify-center">
                <span class="material-symbols-outlined text-xl" matTooltip="Lấy Mẫu">file_download</span>
                <span class="text-sm">Download</span>
              </div>
            </button>
            <button mat-menu-item>
              <div class="flex flex-row space-x-2 items-center justify-center">
                <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_upload</span>
                <span class="text-sm">Upload</span>
              </div>
            </button>
          </mat-menu>
          <div class="hidden lg:flex flex-row space-x-3">
            <div (click)="writeExcelFile()"
              class="flex flex-col items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_download</span>
              <span class="text-xs">Download</span>
            </div>
            <div (click)="uploadfile.click()"
              class="flex flex-col items-center space-x-2 p-2 hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_upload</span>
              <span class="text-xs">Upload</span>
              <input class="hidden" (change)="readExcelFile($event)" type="file" #uploadfile>
            </div>
          </div>
        </div>
        <!-- <div class="flex flex-row space-x-2 items-center">
          <button mat-flat-button color="primary">
            <div (click)="openDialog(dialogTemplate)" class="flex flex-row items-center">
              <span class="material-symbols-outlined">add</span>
              <span class="hidden lg:flex">Sản Phẩm</span>
            </div>
          </button>
          <button class="lg:!hidden flex" mat-icon-button [matMenuTriggerFor]="menu">
            <span class="material-symbols-outlined">more_vert</span>
          </button>
        </div> -->
      </div>
    </div>
    <div class="relative flex flex-col h-full w-full overflow-auto">
      <div class="flex flex-col my-6 mx-4 rounded-lg shadow-xl shadow-gray-200">
        <div class="overflow-x-auto rounded-2xl">
          <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden">
              <table class="min-w-full divide-y divide-gray-200 table-fixed">
                <thead class="bg-white">
                  <tr>
                    <!-- <th scope="col" class="p-4 lg:p-5">
                      <div class="flex items-center">
                        <input id="checkbox-all" aria-describedby="checkbox-1" type="checkbox"
                          class="w-5 h-5 rounded border-gray-300 focus:ring-0 checked:bg-dark-900">
                        <label for="checkbox-all" class="sr-only">checkbox</label>
                      </div>
                    </th> -->
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Họ Tên
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Tiêu Đề
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Nội Dung
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Email
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Địa Chỉ
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                     Ngày Tạo
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  @for (item of FilterLists; track $index) {
                  <tr class="hover:bg-gray-100">
                    <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap lg:p-5">
                      <div class="flex flex-col space-y-2">
                        <span>{{item.Hoten}}</span>
                        <span class="text-xs">{{item.SDT}}</span>
                      </div>
                    </td>
                    <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap lg:p-5">
                      <div>{{item.Title}}</div>
                    </td>
                    <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap lg:p-5">
                      <div>{{item.Noidung}}</div>
                    </td>
                    <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap lg:p-5">
                      <div>{{item.Email}}</div>
                    </td>
                    <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap lg:p-5">
                      <div>{{item.Diachi}}</div>
                    </td>
                    <td class="p-4 text-base font-medium text-gray-900 whitespace-nowrap lg:p-5">
                       <span class="text-xs">{{item.CreateAt|date:'dd/MM/yyyy'}}</span>
                    </td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center mx-4 mt-4 mb-6 bg-white p-3 rounded-lg">
      <mat-paginator class="w-full" [pageSizeOptions]="[10, 20, 50]" [pageSize]="SearchParams.pageSize"
        [length]="this.Lists.totalCount" [pageSizeOptions]="pageSizeOptions" [pageIndex]="SearchParams.pageNumber"
        (page)="onPageChange($event)">
      </mat-paginator>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<ng-template #dialogTemplate>
  <div class="min-w-[672px]">
    <div mat-dialog-title>Thêm Mới</div>
    <div mat-dialog-content>
      <div class="flex flex-col justify-between w-full space-y-4 p-4">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Tiêu Đề</mat-label>
          <input matInput [(ngModel)]="Detail.Tieude" [ngModelOptions]="{standalone: true}" placeholder="Tiêu Đề">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Slug</mat-label>
          <input matInput [(ngModel)]="Detail.Slug" [ngModelOptions]="{standalone: true}" placeholder="Slug">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Newlink</mat-label>
          <input matInput [(ngModel)]="Detail.Newlink" [ngModelOptions]="{standalone: true}" placeholder="Newlink">
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions class="!justify-end">
      <button mat-button class="bg-red-400 hover:bg-red-600 text-white" mat-dialog-close="false">Huỷ</button>
      <button mat-button class="bg-blue-400 hover:bg-blue-600 text-white" mat-dialog-close="true">Đồng Ý</button>
    </div>
  </div>
</ng-template>