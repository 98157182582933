<div class="flex flex-col space-y-3">
  <div class="grid grid-cols-1 gap-6 mb-6 w-full xl:grid-cols-2 2xl:grid-cols-4 p-4">
    @for (item of Thongkes; track $index) {
      <div class="bg-white shadow-lg shadow-gray-200 rounded-2xl p-4 ">
        <div class="flex items-center">
          <div
            class="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-voilet-500 rounded-lg shadow-md shadow-gray-300">
            <span class="material-symbols-outlined">{{item.Icon}}</span>
          </div>
          <div class="flex-shrink-0 ml-3">
            <span class="text-2xl font-bold leading-none text-gray-900">{{item.Value}}</span>
            <h3 class="text-base font-normal text-gray-500">{{item.Title}}</h3>
          </div>
          <div class="flex flex-1 justify-end items-center ml-5 w-0 text-base font-bold text-green-500">
            {{item.Percent}}
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z"
                clip-rule="evenodd"></path>
            </svg>
          </div>
        </div>
      </div>
    }
    <div class="col-span-2 flex flex-col space-y-3 bg-white rounded-lg p-4 border">
      <div class="flex flex-row justify-between">
        <span class="font-bold">Tổng Đơn hàng</span>
      <mat-form-field appearance="outline">
        <mat-label>Chọn Khoảng Thời Gian</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker">
          <input matStartDate placeholder="Từ Ngày" [(ngModel)]="SearchParams.Batdau" [ngModelOptions]="{standalone: true}">
          <input matEndDate placeholder="Đến Ngày" [(ngModel)]="SearchParams.Ketthuc" [ngModelOptions]="{standalone: true}">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Huỷ</button>
            <button mat-raised-button color="primary" matDateRangePickerApply (click)="ChanggeData()">Đồng ý</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
    </div>
      <p-chart type="bar" [data]="basicData" [options]="basicOptions"></p-chart>
    </div>

    <div class="col-span-2 flex flex-col space-y-3 bg-white rounded-lg p-4 border">
      <div class="flex flex-row justify-between">
        <span class="font-bold">Tổng Doanh Thu</span>
      <mat-form-field appearance="outline">
        <mat-label>Chọn Khoảng Thời Gian</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker">
          <input matStartDate placeholder="Từ Ngày" [(ngModel)]="SearchParams.Batdau" [ngModelOptions]="{standalone: true}">
          <input matEndDate placeholder="Đến Ngày" [(ngModel)]="SearchParams.Ketthuc" [ngModelOptions]="{standalone: true}">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Huỷ</button>
            <button mat-raised-button color="primary" matDateRangePickerApply (click)="ChanggeData()">Đồng ý</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
    </div>
      <p-chart type="bar" [data]="basicData" [options]="basicOptions"></p-chart>
    </div>



    <div class="col-span-2 flex flex-col space-y-3 bg-white rounded-lg p-4 border">
      <div class="flex flex-row justify-between">
        <span class="font-bold">Top 10 Sản Phẩm Bán Chạy</span>
      <mat-form-field appearance="outline">
        <mat-label>Chọn Khoảng Thời Gian</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker">
          <input matStartDate placeholder="Từ Ngày" [(ngModel)]="SearchParams.Batdau" [ngModelOptions]="{standalone: true}">
          <input matEndDate placeholder="Đến Ngày" [(ngModel)]="SearchParams.Ketthuc" [ngModelOptions]="{standalone: true}">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Huỷ</button>
            <button mat-raised-button color="primary" matDateRangePickerApply (click)="ChanggeData()">Đồng ý</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
    </div>
      <p-chart type="bar" [data]="basicData" [options]="basicOptions"></p-chart>
    </div>

  </div>


</div>