<ng-container *ngIf="Image?.src; else elseTemplate">
  <div class="p-4 group relative">
    <img loading="lazy"[src]="Image?.src" class="w-50 h-50 border rounded-full">
    <!-- <img loading="lazy"[src]="Image?.src" class="w-full h-full rounded-full mx-auto"  [alt]="Image?.alt"/> -->
    <div
      class="group-hover:block rounded-md hidden absolute inset-0 items-center justify-center bg-black opacity-10"
      (click)="onRemove(Image)">
      <span class="material-icons text-white flex items-center justify-center h-full">
        cancel
      </span>
    </div>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <ngx-dropzone (change)="onSelect($event)" [multiple]="false" class="h-50 p-8 rounded-full relative">
    <ngx-dropzone-label>Chọn File</ngx-dropzone-label>
    <div class="absolute bottom-2 end-4">
      <button
        class="w-11 h-11 flex items-center justify-center rounded-full bg-primary border-2 border-default-50">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          data-lucide="camera" class="lucide lucide-camera w-5 h-5 text-white">
          <path
            d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z">
          </path>
          <circle cx="12" cy="13" r="3"></circle>
        </svg>
      </button>
    </div>
  </ngx-dropzone>
</ng-template>