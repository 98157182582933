<div class="bg-gray-50 relative p-4 w-full h-full md:h-auto">
  <div class="relative bg-white rounded-2xl  shadow-gray-700">
    <div class="flex justify-between items-center p-5 rounded-t border-b">
      <a [routerLink]="['../']" (click)="CloseDrawer()">
        <span class="material-symbols-outlined ">
          close
        </span>
      </a>
      <span class="lg:text-xl text-xs font-semibold">
        {{Detail.Title}}
      </span>
      <button mat-flat-button color="primary" (click)="UpdateUsergroup()">Lưu</button>
    </div>
    <div class="p-4 grid lg:grid-cols-2 grid-cols-1 gap-4">
      <div class="flex flex-col space-y-2">
        <mat-form-field appearance="outline" class="w-full text-xs">
          <mat-label>Tên Sản Phẩm</mat-label>
          <input [(ngModel)]="Detail.Title" [ngModelOptions]="{standalone: true}" type="text" matInput
            class="w-full px-3 py-2 border border-gray-200 rounded" [placeholder]="'Tên Sản Phẩm'">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full text-xs">
          <mat-label>Slug</mat-label>
          <input [(ngModel)]="Detail.Slug" [ngModelOptions]="{standalone: true}" type="text" matInput
            class="w-full px-3 py-2 border border-gray-200 rounded" [placeholder]="'Slug'">
        </mat-form-field>

      </div>
  </div>
</div>