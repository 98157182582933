<div class="bg-gray-50 relative p-4 w-full h-full md:h-auto">
  <div class="relative bg-white rounded-2xl  shadow-gray-700">
    <div class="flex justify-between space-x-4 items-center p-5 rounded-t border-b">
      <a [routerLink]="['../']" (click)="CloseDrawer()">
        <span class="material-symbols-outlined ">
          close
          </span>
        </a>
      <span class="lg:text-xl text-xs font-semibold">
        {{Detail?.Hoten}}
      </span>
      <button mat-flat-button color="primary" (click)="UpdateUseradmin()">Lưu</button>
    </div>
        <div class="p-4 grid lg:grid-cols-3 grid-cols-1 gap-4">
          <div>
            <app-hinhanh [height]="'h-60'" [width]="'w-60'" [Image]="Detail.Image" (UploadEmit)="GetUpload($event)"></app-hinhanh> 
          </div>   

          <div class="flex flex-col space-y-3">
            <mat-form-field appearance="outline" class="w-full text-xs">
              <mat-label>Họ Tên</mat-label>
              <input [(ngModel)]="Detail.Hoten" [ngModelOptions]="{standalone: true}" type="text" matInput 
               class="w-full px-3 py-2 border border-gray-200 rounded"
                [placeholder]="'Họ Tên'">
            </mat-form-field> 
            <mat-form-field appearance="outline" class="w-full text-xs">
              <mat-label>Email</mat-label>
              <input [(ngModel)]="Detail.email" [ngModelOptions]="{standalone: true}" type="text" matInput 
               class="w-full px-3 py-2 border border-gray-200 rounded"
                [placeholder]="'Email'">
            </mat-form-field>           
            <mat-form-field appearance="outline" class="w-full text-xs">
              <mat-label>Số Điện Thoại</mat-label>
              <input [(ngModel)]="Detail.SDT" [ngModelOptions]="{standalone: true}" type="text" matInput 
               class="w-full px-3 py-2 border border-gray-200 rounded"
                [placeholder]="'Số Điện Thoại'">
            </mat-form-field>           
            <mat-form-field appearance="outline" class="w-full text-xs">
                <mat-label>Role</mat-label>
                <mat-select [(ngModel)]="Detail.Role" [ngModelOptions]="{standalone: true}">
                  <mat-option [value]="item.id" *ngFor="let item of ListRole">{{item.value}}</mat-option>
                </mat-select>
              </mat-form-field>
          </div>
        </div>
  </div>
</div> 