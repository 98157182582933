<div class="h-full p-4">
  <div class="!flex flex-col flex-auto min-w-0 relative bg-white p-4 border rounded-lg">
    <div class="relative flex flex-col h-full w-full overflow-auto">
      <table mat-table [dataSource]="dataSource" matSort>
        @for (item of displayedColumns; track $index) {
       <ng-container matColumnDef="{{item}}">
         <th mat-header-cell *matHeaderCellDef mat-sort-header> {{item}} </th>
         <td mat-cell *matCellDef="let row"> {{row[item]}} </td>
       </ng-container>
      }
        <!-- <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let row"> {{row.id}} </td>
        </ng-container>
        <ng-container matColumnDef="Title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
          <td mat-cell *matCellDef="let row"> {{row.Title}} </td>
        </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div class="relative flex flex-0 items-center">
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>
  </div>
</div>