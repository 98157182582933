import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pageadmin',
  templateUrl: './pageadmin.component.html',
  styleUrls: ['./pageadmin.component.css']
})
export class PageadminComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
