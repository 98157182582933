export const environment = {
  production:true,
  APITINYMCE: "1cdi3qs7qw7nogvpu6poxqc6z7bf4a4hurwyao0kdbd741dl",
  pathServer:"/home/zbhykhog/public_html/shop/images",
  APIURL:'https://api.rausachtrangia.com',
  ImageURL:'https://images.rausachtrangia.com/',
  app_id:"1416835846626859002",
  secret_key:"HLBWj23hToA9fuiQvQY4",
  zalo_redirect_uri:"https://shop.chikiet.com/admin/zalotoken",
  telegram_api:"6826345435:AAHkmH3QBTOFDbQH_V8TiyXtP3ClkubJW1E",
  //telegram_group:"-4177356569",
  telegram_group:"@NopTuibWPYc0NDU1",
  firebaseConfig : {
    apiKey: "AIzaSyC1s1lnZqlFJZTkqXmbRZYcza1JyOS4RIg",
    authDomain: "rausachtrangia-808fe.firebaseapp.com",
    projectId: "rausachtrangia-808fe",
    storageBucket: "rausachtrangia-808fe.appspot.com",
    messagingSenderId: "372837233241",
    appId: "1:372837233241:web:380d270269671f26e63748",
    measurementId: "G-1YG96JT0S7"
  }
};
