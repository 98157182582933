import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-cta',
  standalone:true,
  templateUrl: './site-cta.component.html',
  styleUrls: ['./site-cta.component.css']
})
export class SiteCtaComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

}
