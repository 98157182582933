<mat-drawer-container class="example-container w-full h-full " autosize>
  <mat-drawer #drawer mode="side" class="!flex flex-col flex-auto min-w-0 relative h-full w-full" position="end">
    <div class="relative flex flex-col h-full w-full overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer>
  <mat-drawer-content class="!flex flex-col flex-auto min-w-0 relative !h-[90vh]">
    <div class="relative flex flex-col">
      <div class="flex justify-between items-center space-x-2 m-2 bg-white p-3 rounded-lg">
        <div class="lg:w-auto w-full flex flex-row space-x-3 justify-center items-center">
          <mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-full">
            <mat-label>Tìm kiếm</mat-label>
            <input type="text" matInput class="w-full border border-gray-200 rounded" [placeholder]="'Tìm kiếm'"
              (keyup)="applyFilter($event)" />
          </mat-form-field>

          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <div (click)="openDialog(dialogTemplate)" class="flex flex-row space-x-2 items-center justify-center">
                <span class="material-symbols-outlined text-xl">add_circle</span>
                <span class="text-xs">Thêm Mới</span>
              </div>
            </button>
            <button mat-menu-item>
              <div (click)="writeExcelFile()" class="flex flex-row space-x-2 items-center justify-center">
                <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_download</span>
                <span class="text-xs">Download</span>
              </div>
            </button>
            <button mat-menu-item>
              <div (click)="uploadfile.click()" class="flex flex-row space-x-2 items-center justify-center">
                <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_upload</span>
                <span class="text-xs">Upload</span>
                <input class="hidden" (change)="readExcelFile($event)" type="file" #uploadfile>
              </div>
            </button>
            <button mat-menu-item>
              <div (click)="LoadDrive()" class="flex flex-row space-x-2 items-center justify-center">
                <span class="material-symbols-outlined text-xl" matTooltip="Drive">cloud_download</span>
                <span class="text-xs">Drive ({{UsergroupsDrive.length}})</span>
              </div>
            </button>
            @if(UsergroupsDrive.length>0)
            {
            <button mat-menu-item>
              <div (click)="SyncDrive()"
                class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
                <span class="material-symbols-outlined text-xl" matTooltip="Drive">cloud_sync</span>
                <span class="text-xs">Sync ({{FilterLists.length||0}}/{{UsergroupsDrive.length||0}})</span>
              </div>
            </button>
            }
          </mat-menu>
          <div class="hidden lg:flex flex-row space-x-3 items-center whitespace-nowrap">
            <div (click)="writeExcelFile()" class="flex flex-col items-center p-2 hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_download</span>
              <span class="text-xs">Download</span>
            </div>
            <div (click)="uploadfile.click()" class="flex flex-col items-center p-2 hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_upload</span>
              <span class="text-xs">Upload</span>
              <input class="hidden" (change)="readExcelFile($event)" type="file" #uploadfile>
            </div>
            <div (click)="LoadDrive()"
              class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Drive">cloud_download</span>
              <span class="text-xs">Drive ({{UsergroupsDrive.length||0}})</span>
            </div>
            @if(UsergroupsDrive.length>0)
            {
            <div (click)="SyncDrive()"
              class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Drive">cloud_sync</span>
              <span class="text-xs">Sync ({{FilterLists.length||0}}/{{UsergroupsDrive.length||0}})</span>
            </div>
            }
            <div (click)="openDialog(dialogTemplate)"
              class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Thêm Mới">add_circle</span>
              <span class="text-xs">Thêm Mới</span>
            </div>
          </div>
        </div>

        <div class="lg:hidden flex flex-row space-x-2 items-center">
          <span mat-icon-button [matMenuTriggerFor]="menu" class="material-symbols-outlined p-2 border rounded-full 
            bg-slate-200 text-blue-500 
            hover:bg-blue-500 hover:text-white whitespace-nowrap">
            more_vert
          </span>
        </div>
      </div>
    </div>

    <div class="relative flex flex-col h-full overflow-auto rounded-lg m-2">
      <div class="p-2 bg-white">
        <mat-tree [dataSource]="treedataSource" [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <a
              class="cursor-pointer flex flex-row space-x-2 justify-between p-3 w-full rounded-lg hover:bg-gray-200 hover:font-bold"
              [routerLink]="[node.id]" routerLinkActive="bg-gray-100 font-bold">
              <span>
                {{node.Title}}
              </span>
            </a>
          </mat-tree-node>
          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding class="pl-2">
            <div
              class="cursor-pointer flex flex-row space-x-2 items-center justify-between p-3 w-full rounded-lg hover:bg-gray-100 hover:font-bold">
              <span>
                {{node.Title}}
              </span>
              <span mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.Title">
                <span
                  class="material-symbols-outlined">{{treeControl.isExpanded(node)?'expand_more':'navigate_next'}}</span>
              </span>
            </div>
          </mat-tree-node>
        </mat-tree>


      <!-- <mat-tree [dataSource]="treedataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <a [routerLink]="node.id">
            <button mat-icon-button disabled></button>
           <span>{{node.Title}}</span>
          </a>
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
          <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
            <span class="material-symbols-outlined">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </span>
          </button>
          <a [routerLink]="node.id">
            {{node.Title}}
          </a>
        </mat-tree-node>
      </mat-tree> -->
    </div>
    </div>
    <div class="flex justify-between items-center mx-4 mt-4 mb-6 bg-white p-3 rounded-lg">
      <mat-paginator class="w-full" [pageSizeOptions]="[10, 20, 50]" [pageSize]="SearchParams.pageSize"
        [length]="this.Lists.totalCount" [pageSizeOptions]="pageSizeOptions" [pageIndex]="SearchParams.pageNumber"
        (page)="onPageChange($event)">
      </mat-paginator>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<ng-template #dialogTemplate>
  <div class="lg:w-[40vw] p-2 flex flex-col space-y-3">
    <div mat-dialog-title>Thêm Mới</div>
    <div mat-dialog-content>
      <div class="flex flex-col w-full space-y-4 lg:p-4">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Tiêu Đề</mat-label>
          <input matInput [(ngModel)]="Detail.Title" [ngModelOptions]="{standalone: true}" placeholder="Tiêu Đề"
            (input)="FillSlug()">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Slug</mat-label>
          <input matInput [(ngModel)]="Detail.Slug" [ngModelOptions]="{standalone: true}" placeholder="Slug">
        </mat-form-field>
        <!-- <mat-form-field appearance="outline">
          <mat-label>Danh Mục</mat-label>
          <mat-select [(ngModel)]="Detail.cat_id" [ngModelOptions]="{standalone: true}">
            <mat-option [value]="item.id" *ngFor="let item of ListDanhmuc">{{item.Title}}</mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
      <div class="flex flex-row space-x-2 items-center justify-center">
        <button mat-flat-button color="warn" mat-dialog-close="false">Huỷ</button>
        <button mat-flat-button color="primary" mat-dialog-close="true">Đồng Ý</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #XoaTeamplate>
  <div class="lg:w-[40vw] p-2 flex flex-col space-y-3">
    <div mat-dialog-title>Xoá</div>
    <div mat-dialog-content>
      <div class="flex flex-col w-full space-y-4 lg:p-4 p-2">
        Bạn chắc chắn muốn Xoá?
      </div>
      <div class="flex flex-row space-x-2 items-center justify-center">
        <button mat-flat-button color="warn" mat-dialog-close="false">Huỷ</button>
        <button mat-flat-button color="primary" mat-dialog-close="true">Đồng Ý</button>
      </div>
    </div>
  </div>
</ng-template>