<footer class="bottom pt-12 bg-black text-white">
    <!-- <div [innerHTML]="sanitizeHtml(Detail?.Dulieu?.Footer)"></div> -->
    <div class="container">
        <div class="grid lg:grid-cols-10 grid-cols-1 gap-4 pb-12">
            <div class="lg:col-span-4 col-span-1 flex flex-col space-y-2">
                <div class="!font-bold !text-xl">THÔNG TIN LIÊN HỆ</div>
                <div class="flex flex-col space-y-3">
                    <span class="text-lg text-[#65b009]"><strong>CTY TNHH NÔNG SẢN THỰC PHẨM TRẦN GIA</strong></span>
                    <span><strong>Địa chỉ:</strong> Tầng 3, An Phú Plaza, 117-119 Lý Chính Thắng P. Võ Thị Sáu, Q.3,
                        TPHCM</span>
                    <span><strong>Hotline:</strong> 0865.77.0009</span>
                    <span><strong>Email:</strong> <a
                            href="mailto:mart.rausachtrangia@gmail.com">mart.rausachtrangia&#64;gmail.com</a></span>

                </div>
            </div>
            <div class="lg:col-span-2 grid-cols-1 col-span-1 flex flex-col space-y-2">
                <div class="!font-bold !text-xl">VỀ CHÚNG TÔI</div>
                <div class="flex flex-col space-y-2">
                    <span><a href="blog/gioi-thieu/ve-chung-toi">GIỚI THIỆU</a></span>
                    <span><a href="#">KHUYẾN MÃI</a></span>
                    <span><a href="#">MÓN NGON MỖI NGÀY</a></span>
                    <span><a href="#">TIN TỨC</a></span>
                    <span><a href="#">LIÊN HỆ</a></span>
                </div>
                <div class="flex flex-row space-x-2 p-2 bg-white rounded-lg">
                    <a href="https://www.facebook.com/rausachtrangia2011" target="_blank" rel="noopener">
                        <img class="w-10" src="assets/images/icon/facebook.png" width="123" height="123">
                    </a>
                    <a title="Tiktok" href="https://www.tiktok.com/@rausachtrangia.com?_t=8maDzCUcrPs&_r=1" target="_blank"
                    rel="noopener">
                    <img class="w-10" src="assets/images/icon/tiktok.png" width="116" height="116">
                    </a>
                    <a title="Youtube Channel" href="https://www.youtube.com/@rausachtrangia1649" target="_blank"
                        rel="noopener">
                        <img class="w-10" src="assets/images/icon/youtube.png" width="118" height="118">
                    </a>
                </div>
            </div>
            <div class="lg:col-span-2 grid-cols-1 col-span-1 flex flex-col space-y-3">
                <div class="!font-bold !text-xl">CHÍNH SÁCH QUY ĐỊNH</div>
                <a href="../../blog/chinh-sach-quy-dinh/chinh-sach-bao-mat" target="_blank" rel="noopener">Chính
                    sách bảo mật</a>
                <a href="../../blog/chinh-sach-quy-dinh/chinh-sach-thanh-toan" target="_blank" rel="noopener">Chính
                    sách thanh toán</a>

                <a href="../../blog/chinh-sach-quy-dinh/chinh-sach-giao-hang" target="_blank" rel="noopener">Chính
                    sách giao hàng</a>
                <a href="../../blog/chinh-sach-quy-dinh/chinh-sach-doi-tra">Chính
                    sách đổi trả</a>
            </div>
            <div class="lg:col-span-2 grid-cols-1 col-span-1">
                <div class="!font-bold !text-xl">THỐNG KÊ TRUY CẬP</div>
                <div class="flex flex-col space-y-3">
                    <div class="flex flex-row space-x-2 items-center">
                        <span class="material-symbols-outlined"> bar_chart </span>
                        <a href="#">Đang truy cập:</a>
                        <span>{{Visitors.Hientai|number:'1.0-0'}}</span>
                    </div>
                    <div class="flex flex-row space-x-2 items-center">
                        <span class="material-symbols-outlined"> bar_chart </span>
                        <a href="#">Hôm nay</a>
                        <span>{{Visitors.Ngay|number:'1.0-0'}}</span>
                    </div>
                    <div class="flex flex-row space-x-2 items-center">
                        <span class="material-symbols-outlined"> bar_chart </span>
                        <a href="#">Trong tháng</a>
                        <span>{{Visitors.Thang|number:'1.0-0'}}</span>
                    </div>
                    <div class="flex flex-row space-x-2 items-center">
                        <span class="material-symbols-outlined"> bar_chart </span>
                        <a href="#">Tổng truy cập</a>
                        <span>{{Visitors.Tong|number:'1.0-0'}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-4 bg-[#d7d7d7]">
        <div class="flex justify-center items-center container">
            <div class="text-sm text-center text-black">
                <span>© Copyright {{Today|date:'yyyy'}} <a href="/">CÔNG TY TNHH NÔNG SẢN THỰC PHẨM TRẦN GIA</a></span>
            </div>
        </div>
    </div>
</footer>