<button (click)="UpdateDulieu()" mat-flat-button color="primary">Lưu</button>
<footer class="bottom pt-12">
    <div class="container flex flex-col space-y-2">
        <editor [apiKey]="APITINYMCE" [(ngModel)]="Dulieu.Footer" [ngModelOptions]="{standalone: true}"
            [init]="configTiny"></editor>
        <!-- <div [innerHTML]="Dulieu.Footer"></div> -->

        <!-- <div class="grid grid-cols-1 gap-4 pb-12">
            <div class="lg:col-span-3 col-span-1">
                <h4 class="!font-bold !text-lg">THÔNG TIN LIÊN HỆ</h4>
                <ul class="flex flex-col space-y-3">
                    @for (item of FilterFooter(Dulieu.Data,'Group','Lienhe'); track $index) {
                    <li class="text-gray-500 rounded-lg border grid grid-cols-1 gap-4 p-4">
                        <a class="{{item.Class}}" [href]="item.Slug">{{item.Title}}</a>

                        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-full">
                            <mat-label>Tiêu Đề</mat-label>
                            <input [(ngModel)]="Dulieu.Data[item.id-1].Title" [ngModelOptions]="{standalone: true}"
                                type="text" matInput class="w-full border border-gray-200 rounded"
                                [placeholder]="item.Title" />
                        </mat-form-field>
                        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-full">
                            <mat-label>Liên kết</mat-label>
                            <input [(ngModel)]="Dulieu.Data[item.id-1].Slug" [ngModelOptions]="{standalone: true}"
                                type="text" matInput class="w-full border border-gray-200 rounded"
                                [placeholder]="item.Slug" />
                        </mat-form-field>
                    </li>
                    }
                </ul>
            </div>
            <div class="lg:col-span-2 grid-cols-1 col-span-1">
                <h4 class="!font-bold !text-lg">VỀ CHÚNG TÔI</h4>
                <ul class="flex flex-col space-y-2">
                    @for (item of FilterFooter(Dulieu.Data,'Group','Vechungtoi'); track $index) {
                    <li class="text-gray-500 rounded-lg border grid grid-cols-1 gap-4 p-4">
                        <a [href]="item.Slug">{{item.Title}}</a>
                        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-full">
                            <mat-label>Tiêu Đề</mat-label>
                            <input [(ngModel)]="Dulieu.Data[item.id-1].Title" [ngModelOptions]="{standalone: true}"
                                type="text" matInput class="w-full border border-gray-200 rounded"
                                [placeholder]="item.Title" />
                        </mat-form-field>
                        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-full">
                            <mat-label>Liên kết</mat-label>
                            <input [(ngModel)]="Dulieu.Data[item.id-1].Slug" [ngModelOptions]="{standalone: true}"
                                type="text" matInput class="w-full border border-gray-200 rounded"
                                [placeholder]="item.Slug" />
                        </mat-form-field>
                    </li>
                    }
                    <div class="flex flex-row space-x-2">
                        <img loading="lazy" src="assets/images/icon/facebook.png" class="w-10" />
                        <img loading="lazy" src="assets/images/icon/googleplus.png" class="w-10" />
                        <img loading="lazy" src="assets/images/icon/youtube.png" class="w-10" />
                    </div>
                </ul>
            </div>
            <div class="lg:col-span-2 grid-cols-1 col-span-1">
                <h4 class="!font-bold !text-lg">CHÍNH SÁCH QUY ĐỊNH</h4>
                <ul class="flex flex-col space-y-3">
                    @for (item of FilterFooter(Dulieu.Data,'Group','Chinhsachquydinh'); track $index) {
                    <li class="text-gray-500 text-xs  rounded-lg border grid grid-cols-1 gap-4 p-4">
                        <a [href]="item.Slug">{{item.Title}}</a>
                    </li>
                    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-full">
                        <mat-label>Tiêu Đề</mat-label>
                        <input [(ngModel)]="Dulieu.Data[item.id-1].Title" [ngModelOptions]="{standalone: true}"
                            type="text" matInput class="w-full border border-gray-200 rounded"
                            [placeholder]="item.Title" />
                    </mat-form-field>
                    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-full">
                        <mat-label>Liên kết</mat-label>
                        <input [(ngModel)]="Dulieu.Data[item.id-1].Slug" [ngModelOptions]="{standalone: true}"
                            type="text" matInput class="w-full border border-gray-200 rounded"
                            [placeholder]="item.Slug" />
                    </mat-form-field>
                    }
                </ul>
            </div>
            <div class="lg:col-span-2 grid-cols-1 col-span-1">
                <h4 class="!font-bold !text-lg">THỐNG KÊ TRUY CẬP</h4>
                <ul class="flex flex-col space-y-3">
                    @for (item of FilterFooter(Dulieu.Data,'Group','Thongketruycap'); track $index) {
                    <li class="text-gray-500  rounded-lg border grid grid-cols-1 gap-4 p-4">
                        <div class="flex flex-row space-x-2 items-center">
                            <span class="material-symbols-outlined">bar_chart</span><a
                                [href]="item.Slug">{{item.Title}}</a>
                        </div>
                        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-full">
                            <mat-label>Tiêu Đề</mat-label>
                            <input [(ngModel)]="Dulieu.Data[item.id-1].Title" [ngModelOptions]="{standalone: true}"
                                type="text" matInput class="w-full border border-gray-200 rounded"
                                [placeholder]="item.Title" />
                        </mat-form-field>
                        <mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-full">
                            <mat-label>Liên kết</mat-label>
                            <input [(ngModel)]="Dulieu.Data[item.id-1].Slug" [ngModelOptions]="{standalone: true}"
                                type="text" matInput class="w-full border border-gray-200 rounded"
                                [placeholder]="item.Slug" />
                        </mat-form-field>
                    </li>
                    }
                </ul>
            </div>
        </div>
        <div class="py-12 border-t border-pgray-100">
            <div class="grid lg:grid-cols-2 grid-cols-1 gap-7">
                <div class="text-pgray-400 text-sm text-center">
                    <p>© Copyright {{Today|date:'yyyy'}} <a href="#">CÔNG TY TNHH NÔNG SẢN THỰC PHẨM TRẦN GIA</a></p>
                </div>
            </div>
        </div> -->
    </div>
</footer>