<div class="flex flex-col space-y-6 p-4">
  <a href="san-pham/{{Detail.Slug}}" class="relative cursor-pointer z-10 grid grid-cols-1 border rounded-lg p-3 bg-white transform duration-500 hover:scale-[110%] hover:shadow-lg">
    @if(Detail && Detail.Image&&Detail.Image.Main)
    {
    <img loading="lazy" src="assets/images/sanpham/{{Detail.Image.Main}}" class="w-full">
    }
    @else if(Detail && Detail.Image&&Detail.Image.Hinhchinh&&Detail.Image.Hinhchinh.src){
    <img loading="lazy" src="{{Detail.Image.Hinhchinh.src}}" class="w-full">
    }
    @else if(Detail && Detail.Image&&Detail.Image.Hinhchinh&&Detail.Image.Hinhchinh.src){
    <img loading="lazy" src="assets/images/noimage.png" class="p-3 w-full mx-auto rounded-lg">
    }
    <div (click)="AddtoCart(Detail);$event.preventDefault()"
      class="absolute bottom-2 right-2 z-20 flex flex-row space-x-2 justify-end">
      <span
        class="z-10 material-symbols-outlined rounded-lg p-2 bg-[#FAA61A] hover:bg-[#D89016] text-white">shopping_cart</span>
    </div>
  </a>
  <div class="flex flex-col lg:space-y-4 space-y-1 justify-start">
  <span class="text-base font-bold line-clamp-2 min-h-12">{{Detail.Title}} <br/></span>
  <div class="cursor-pointer flex flex-row items-center justify-between">
    <div class="flex flex-row space-x-2">
      <span class="text-[#E1232A] font-bold text-base"> {{Detail?.Giagoc[0]?.gia| number:'1.0-0'}}đ</span>
      <span>/{{Detail?.Giagoc[0]?.khoiluong}}{{Detail?.Giagoc[0]?.dvt}}</span>
    </div>
    @if(isMuangay){
      <div (click)="AddtoCart(Detail);$event.preventDefault()" class="hidden lg:block">
        <span class="whitespace-nowrap rounded-lg p-2 bg-[#E1232A] hover:bg-[#C51D23] text-white">Mua Ngay</span>
      </div>
    }

  </div> 
  </div>
</div>
