<div class="w-full flex flex-col justify-center items-center">
  <img loading="lazy" src="/assets/images/tunongtraidenbanan.jpg" class="lg:flex hidden">
  <div class="{{isSticky?'fixed top-0 border border-b':''}} w-full z-10">
    <div class="w-full bg-white">
      <app-header></app-header>
    </div>
  </div>
  <router-outlet></router-outlet>
  <section class="bg-gray-100 w-full">
    <div class="container grid lg:grid-cols-2 grid-cols-1 gap-4 relative p-8">
      <img loading="lazy" src="/assets/images/logo-full.png" class="h-20">
      <div class="flex flex-col space-y-2 lg:text-justify text-center">
        <span class="text-center font-bold">Đăng ký Nhận Thông Tin Khuyến Mãi</span>
        <div class="flex flex-row space-x-2">
          <input [(ngModel)]="Detail.Email" [ngModelOptions]="{standalone: true}"
            class="block w-full rounded-lg p-2 text-sm text-gray-900 border" placeholder="Nhập Địa Chỉ Email">
          <button (click)="CreateLienhe()" type="submit"
            class="whitespace-nowrap text-white end-2.5 bottom-2.5 bg-[#FAA61A] rounded-lg text-sm px-4 py-2">Đăng
            Ký</button>
        </div>
      </div>
    </div>
  </section>
  <app-footer class="w-full"></app-footer>
  <!-- <footer class="bottom pt-12 bg-white">
  <div class="container">
    <div class="grid lg:grid-cols-9 grid-cols-1 gap-4 pb-12">
      <div class="lg:col-span-3 col-span-1">
        <h4 class="!font-bold !text-lg">THÔNG TIN LIÊN HỆ</h4>
        <ul class="flex flex-col space-y-3">
          <li class="text-gray-500">
            <p><span style="font-size: 14pt;"><strong>CTY TNHH NÔNG SẢN THỰC PHẨM TRẦN GIA</strong></span></p>
          </li>
          <li class="text-gray-500">
            <p><span style="font-size: 14pt;"><strong>Địa chỉ:</strong> Tầng 3, An Phú Plaza, 117-119 Lý Chính Thắng P.
                Võ Thị Sáu, Q. 3, TPHCM</span></p>
          </li>
          <li class="text-gray-500" style="font-size: 14pt;">
            <p><span style="font-size: 14pt;"><strong>Hotline:</strong> 0865.77.0009</span></p>
          </li>
          <li class="text-gray-500" style="font-size: 14pt;">
            <p><span style="font-size: 14pt;"><strong>Email:</strong> <a
                  href="mailto:mart.rausachtrangia@gmail.com">mart.rausachtrangia&#64;gmail.com</a></span></p>
          </li>
        </ul>
      </div>
      <div class="lg:col-span-2 grid-cols-1 col-span-1">
        <h4 class="!font-bold !text-lg">VỀ CHÚNG TÔI</h4>
        <ul class="flex flex-col space-y-2">
          <li class="text-gray-500">
            <p><span style="font-size: 14pt;"><a href="blog/gioi-thieu/ve-chung-toi">GIỚI THIỆU</a></span></p>
          </li>
          <li class="text-gray-500" style="font-size: 14pt;">
            <p><span style="font-size: 14pt;"><a href="#">KHUYẾN MÃI</a></span></p>
          </li>
          <li class="text-gray-500" style="font-size: 14pt;">
            <p><span style="font-size: 14pt;"><a href="#">MÓN NGON MỖI NGÀY</a></span></p>
          </li>
          <li class="text-gray-500" style="font-size: 14pt;">
            <p><span style="font-size: 14pt;"><a href="#">TIN TỨC</a></span></p>
          </li>
          <li class="text-gray-500" style="font-size: 14pt;">
            <p><span style="font-size: 14pt;"><a href="#">LIÊN HỆ</a></span></p>
          </li>
        </ul>
        <div class="flex flex-row space-x-2"><a href="https://www.facebook.com/rausachtrangia2011" target="_blank"
            rel="noopener"><img class="w-10" src="assets/images/icon/facebook.png" width="123" height="123"></a> <img
            class="w-10" src="assets/images/icon/tiktok.png" width="116" height="116"> <a title="Youtube Channel"
            href="https://www.youtube.com/@rausachtrangia1649" target="_blank" rel="noopener"><img class="w-10"
              src="assets/images/icon/youtube.png" width="118" height="118"></a></div>
      </div>
      <div class="lg:col-span-2 grid-cols-1 col-span-1">
        <h4 class="!font-bold !text-lg">CHÍNH SÁCH QUY ĐỊNH</h4>
        <ul class="flex flex-col space-y-3">
          <li class="text-gray-500 text-xs">
            <p><span style="font-size: 14pt;"><a href="../../blog/chinh-sach-quy-dinh/chinh-sach-bao-mat"
                  target="_blank" rel="noopener">Chính sách bảo mật</a></span></p>
          </li>
          <li class="text-gray-500 text-xs" style="font-size: 14pt;">
            <p><span style="font-size: 14pt;"><a href="../../blog/chinh-sach-quy-dinh/chinh-sach-thanh-toan"
                  target="_blank" rel="noopener">Chính sách thanh toán</a></span></p>
          </li>
          <li class="text-gray-500 text-xs" style="font-size: 14pt;">
            <p><span style="font-size: 14pt;"><a href="../../blog/chinh-sach-quy-dinh/chinh-sach-giao-hang"
                  target="_blank" rel="noopener">Chính sách giao hàng</a></span></p>
          </li>
          <li class="text-gray-500 text-xs" style="font-size: 14pt;">
            <p><span style="font-size: 14pt;"><a href="../../blog/chinh-sach-quy-dinh/chinh-sach-doi-tra">Chính sách đổi
                  trả</a></span></p>
          </li>
        </ul>
      </div>
      <div class="lg:col-span-2 grid-cols-1 col-span-1">
        <p class="!font-bold !text-lg">THỐNG KÊ TRUY CẬP</p>
        <ul class="flex flex-col space-y-3">
          <li class="text-gray-500 flex flex-row space-x-2 items-center">
            <h4><span class="material-symbols-outlined"> bar_chart </span><a href="#">Đang truy cập</a></h4>
          </li>
          <li class="text-gray-500 flex flex-row space-x-2 items-center">
            <h4><span class="material-symbols-outlined"> bar_chart </span><a href="#">Hôm nay</a></h4>
          </li>
          <li class="text-gray-500 flex flex-row space-x-2 items-center">
            <h4><span class="material-symbols-outlined"> bar_chart </span><a href="#">Trong tháng</a></h4>
          </li>
          <li class="text-gray-500 flex flex-row space-x-2 items-center">
            <h4><span class="material-symbols-outlined"> bar_chart </span><a href="#">Tổng truy cập</a></h4>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer> -->
</div>
<app-site-cta></app-site-cta>