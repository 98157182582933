<div class="flex justify-center items-center lg:max-w-lg">
  <div class="flex flex-col h-full">
      <div class="shrink">
          <div class="py-4">
              <h1 class="text-3xl font-semibold text-default-800 mb-2">Đăng Nhập</h1>
          </div>
          <form data-x-form="" data-x-form-to="home.html">
              <div class="mb-6">
                  <label class="block text-sm font-medium text-default-900 mb-2" for="LoggingEmailAddress">Số Điện Thoại</label>
                  <input [(ngModel)]="User.SDT" [ngModelOptions]="{standalone: true}"
                  class="block w-full rounded-full py-2.5 px-4 bg-white border border-default-200 focus:ring-transparent focus:border-default-200 dark:bg-default-50" placeholder="Vui lòng nhập số điện thoại">
              </div>
              <div class="mb-6">
                  <div class="flex items-center justify-between mb-2">
                      <label class="block text-sm font-medium text-default-900" for="form-password">Mật Khẩu</label>
                      <a href="quenmatkhau" class="text-xs text-default-700">Quên Mật Khẩu ?</a>
                  </div>
                  <div class="flex">
                      <input [(ngModel)]="User.password" [ngModelOptions]="{standalone: true}"
                      type="password" class="form-password block w-full rounded-s-full py-2.5 px-4 bg-white border !border-gray-200 focus:ring-transparent focus:border-gray-300 dark:bg-default-50" placeholder="Mật Khẩu">
                      <button type="button" class="password-toggle inline-flex items-center justify-center py-2.5 px-4 border rounded-e-full bg-white -ms-px border-default-200 dark:bg-default-50">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="eye" class="lucide lucide-eye password-eye-on h-5 w-5 text-default-600"><path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="eye-off" class="lucide lucide-eye-off password-eye-off h-5 w-5 text-default-600 hidden"><path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path><path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path><path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path><line x1="2" x2="22" y1="2" y2="22"></line></svg>
                      </button>
                  </div>
              </div>
              <div class="flex justify-center">
                  <button (click)="Dangnhap()" type="submit" class="relative inline-flex items-center justify-center px-6 py-3 rounded-full text-base bg-[#e22026] text-white capitalize transition-all hover:bg-[#e8686c] w-full">Đăng Nhập </button>
              </div>
              <!-- <div class="mb-6">
                  <div class="flex items-center justify-center gap-4">
                      <a href="javascript:void(0)" class="">
                          <img loading="lazy" src="/assets/images/google.svg" class="h-8 w-8">
                      </a>
                      <a href="javascript:void(0)" class="">
                          <img loading="lazy" src="/assets/images/facebook.svg" class="h-8 w-8">
                      </a>
                  </div>
              </div> -->
          </form>
      </div>
      <div class="grow flex items-end justify-center mt-4">
          <p class="text-default-950 text-center mt-auto">Chưa có tài khoản ? <a href="dangky" class="text-primary ms-1"><span class="font-medium">Đăng Ký</span></a></p>
      </div>
  </div>
</div>
