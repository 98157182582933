<mat-drawer-container class="example-container w-full h-full " autosize>
  <mat-drawer #drawer mode="side" class="!flex flex-col flex-auto min-w-0 relative h-full w-full" position="end">
    <div class="relative flex flex-col h-full w-full overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer>
  <mat-drawer-content class="!flex flex-col flex-auto min-w-0 relative !h-[90vh]">
    <div class="relative flex flex-col">
      <div class="flex justify-between items-center space-x-2 m-2 bg-white p-3 rounded-lg">
        <div class="lg:w-auto w-full flex flex-row space-x-3 justify-center items-center">

          <mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-full">
            <mat-label>Tìm kiếm</mat-label>
            <input type="text" matInput class="w-full border border-gray-200 rounded" [placeholder]="'Tìm kiếm'"
              (keyup)="applyFilter($event)" />
          </mat-form-field>

          <mat-menu #menu="matMenu">
            <button mat-menu-item>
              <div (click)="openDialog(dialogTemplate)" class="flex flex-row space-x-2 items-center justify-center">
                <span class="material-symbols-outlined text-xl">add_circle</span>
                <span class="text-xs">Thêm Mới</span>
              </div>
            </button>
            <button mat-menu-item>
              <div (click)="writeExcelFile()" class="flex flex-row space-x-2 items-center justify-center">
                <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_download</span>
                <span class="text-xs">Download</span>
              </div>
            </button>
            <button mat-menu-item>
              <div (click)="uploadfile.click()" class="flex flex-row space-x-2 items-center justify-center">
                <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_upload</span>
                <span class="text-xs">Upload</span>
                <input class="hidden" (change)="readExcelFile($event)" type="file" #uploadfile>
              </div>
            </button>
            <button mat-menu-item>
              <div (click)="LoadDrive()" class="flex flex-row space-x-2 items-center justify-center">
                <span class="material-symbols-outlined text-xl" matTooltip="Drive">cloud_download</span>
                <span class="text-xs">Drive ({{SanphamsDrive.length}})</span>
              </div>
            </button>
            @if(SanphamsDrive.length>0)
            {
            <button mat-menu-item>
              <div (click)="SyncDrive()"
                class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
                <span class="material-symbols-outlined text-xl" matTooltip="Drive">cloud_sync</span>
                <span class="text-xs">Sync ({{FilterLists.length||0}}/{{SanphamsDrive.length||0}})</span>
              </div>
            </button>
            }
          </mat-menu>
          <div class="hidden lg:flex flex-row space-x-3 items-center whitespace-nowrap">
            <div (click)="writeExcelFile()" class="flex flex-col items-center p-2 hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_download</span>
              <span class="text-xs">Download</span>
            </div>
            <div (click)="uploadfile.click()" class="flex flex-col items-center p-2 hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_upload</span>
              <span class="text-xs">Upload</span>
              <input class="hidden" (change)="readExcelFile($event)" type="file" #uploadfile>
            </div>
            <div (click)="LoadDrive()"
              class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Drive">cloud_download</span>
              <span class="text-xs">Drive ({{SanphamsDrive.length||0}})</span>
            </div>
            @if(SanphamsDrive.length>0)
            {
            <div (click)="SyncDrive()"
              class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Drive">cloud_sync</span>
              <span class="text-xs">Sync ({{FilterLists.length||0}}/{{SanphamsDrive.length||0}})</span>
            </div>
            }
            <div (click)="openDialog(dialogTemplate)"
              class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Thêm Mới">add_circle</span>
              <span class="text-xs">Thêm Mới</span>
            </div>
          </div>
        </div>
        <div class="lg:hidden flex flex-row space-x-2 items-center">
            <span mat-icon-button [matMenuTriggerFor]="menu" class="material-symbols-outlined p-2 border rounded-full 
            bg-slate-200 text-blue-500 
            hover:bg-blue-500 hover:text-white whitespace-nowrap">
            more_vert
            </span>
        </div>
      </div>
    </div>
    <div class="relative flex flex-col h-full w-full overflow-auto">
      <app-customtable [Dulieus]="FilterLists"></app-customtable>
      <div class="flex flex-col my-6 mx-2 rounded-lg shadow-xl shadow-gray-200">
        <div class="overflow-x-auto rounded-2xl">
          <div class="inline-block min-w-full align-middle">
            <div class="overflow-hidden">
              <table class="min-w-full divide-y divide-gray-200 table-fixed">
                <thead class="bg-white">
                  <tr>
                    <th scope="col" class="p-4 lg:p-5">
                      <div class="flex items-center">
                        <input id="checkbox-all" aria-describedby="checkbox-1" type="checkbox"
                          class="w-5 h-5 rounded border-gray-300 focus:ring-0 checked:bg-dark-900">
                        <label for="checkbox-all" class="sr-only">checkbox</label>
                      </div>
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Hình ảnh
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Tên Sản Phẩm
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Danh Mục
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Trạng Thái
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Bán Chạy
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Nổi Bật
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Mới
                    </th>
                    <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                      Hành Động
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  @for (item of FilterLists; track $index) {
                  <tr class="hover:bg-gray-100">
                    <td class="p-4 w-4 lg:p-5">
                      <div class="flex items-center">
                        <input id="checkbox-194556" aria-describedby="checkbox-1" type="checkbox"
                          class="w-5 h-5 rounded border-gray-300 focus:ring-0 checked:bg-dark-900">
                        <label for="checkbox-194556" class="sr-only">checkbox</label>
                      </div>
                    </td>
                    <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap lg:p-5">
                      <div class="text-base font-semibold text-gray-900">
                        @if(item && item.Image&&item.Image.Thumb)
                        {
                        <img loading="lazy" src="assets/images/sanpham/{{item.Image.Thumb}}" class="w-20">
                        }
                        @else if(item && item.Image&&item.Image.Hinhchinh&&item.Image.Hinhchinh.src){
                        <img loading="lazy" src="{{item.Image.Hinhchinh.src}}" class="w-20">
                        }
                        @else if(item && item.Image&&item.Image.Hinhchinh&&item.Image.Hinhchinh.src){
                        <img loading="lazy" src="assets/images/noimage.png" class="p-3 max-w-32 mx-auto rounded-lg">
                        }
                      </div>
                    </td>
                    <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap lg:p-5">
                      <a routerLink="{{item.id}}" (click)="drawer.toggle()">
                        <div class="text-base font-semibold text-gray-900">{{item.Title}}</div>
                        <div class="italic text-xs font-normal text-gray-500">{{item.Slug}}</div>
                        <div class="text-xs font-normal text-gray-500">
                          Mã SP : <strong>{{item.MaSP}}</strong>
                        </div>
                      </a>
                    </td>
                    <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap lg:p-5">
                      <div class="text-base font-semibold text-gray-900">{{GetTenDanhmuc(item.id_cat)}}</div>
                    </td>
                    <td class="cursor-pointer p-4 text-base font-medium text-gray-900 whitespace-nowrap lg:p-5">
                      <div (click)="ChangeStatus(item,'Status')">
                        @if (item.Status==1) {
                        <span class="material-symbols-outlined text-blue-500">check_circle</span>
                        }
                        @else { <span class="material-symbols-outlined text-red-500">cancel</span> }
                      </div>
                    </td>
                    <td class="cursor-pointer p-4 text-base font-medium text-gray-900 whitespace-nowrap lg:p-5">
                      <div (click)="ChangeStatus(item,'Banchay')">
                        @if (item.Banchay==1) {
                        <span class="material-symbols-outlined text-blue-500">check_circle</span>
                        }
                        @else { <span class="material-symbols-outlined text-red-500">cancel</span> }
                      </div>
                    </td>
                    <td class="cursor-pointer p-4 text-base font-medium text-gray-900 whitespace-nowrap lg:p-5">
                      <div (click)="ChangeStatus(item,'Noibat')">
                        @if (item.Noibat==1) {
                        <span class="material-symbols-outlined text-blue-500">check_circle</span>
                        }
                        @else { <span class="material-symbols-outlined text-red-500">cancel</span> }
                      </div>
                    </td>
                    <td class="cursor-pointer p-4 text-base font-medium text-gray-900 whitespace-nowrap lg:p-5">
                      <div (click)="ChangeStatus(item,'Moi')">
                        @if (item.Moi==1) {
                        <span class="material-symbols-outlined text-blue-500">check_circle</span>
                        }
                        @else { <span class="material-symbols-outlined text-red-500">cancel</span> }
                      </div>
                    </td>
                    <td class="p-4 space-x-2 whitespace-nowrap lg:p-5">
                      <a routerLink="{{item.id}}" mat-flat-button color="primary">
                        <div class="flex flex-row space-x-2 items-center">
                          <span class="material-symbols-outlined">edit_square</span>
                        </div>
                      </a>
                      <button (click)="XoaDialog(XoaTeamplate);SelectItem=item" mat-flat-button color="warn">
                        <div class="flex flex-row space-x-2 items-center">
                          <span class="material-symbols-outlined">delete</span>
                        </div>
                      </button>
                    </td>
                  </tr>
                  }

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center mx-4 mt-4 mb-6 bg-white p-3 rounded-lg">
      <mat-paginator class="w-full" [pageSizeOptions]="[10, 20, 50]" [pageSize]="SearchParams.pageSize"
        [length]="this.Lists.totalCount" [pageSizeOptions]="pageSizeOptions" [pageIndex]="SearchParams.pageNumber"
        (page)="onPageChange($event)">
      </mat-paginator>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
<ng-template #dialogTemplate>
  <div class="lg:w-[40vw] p-2 flex flex-col space-y-3">
    <div mat-dialog-title>Thêm Mới</div>
    <div mat-dialog-content>
      <div class="flex flex-col w-full space-y-4 lg:p-4">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Tiêu Đề</mat-label>
          <input matInput [(ngModel)]="Detail.Title" [ngModelOptions]="{standalone: true}" placeholder="Tiêu Đề"
            (input)="FillSlug()">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Slug</mat-label>
          <input matInput [(ngModel)]="Detail.Slug" [ngModelOptions]="{standalone: true}" placeholder="Slug">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Danh Mục</mat-label>
          <mat-select [(ngModel)]="Detail.cat_id" [ngModelOptions]="{standalone: true}">
            <mat-option [value]="item.id" *ngFor="let item of ListDanhmuc">{{item.Title}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex flex-row space-x-2 items-center justify-center">
        <button mat-flat-button color="warn" mat-dialog-close="false">Huỷ</button>
        <button mat-flat-button color="primary" mat-dialog-close="true">Đồng Ý</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #XoaTeamplate>
  <div class="lg:w-[40vw] p-2 flex flex-col space-y-3">
    <div mat-dialog-title>Xoá</div>
    <div mat-dialog-content>
      <div class="flex flex-col w-full space-y-4 lg:p-4 p-2">
        Bạn chắc chắn muốn Xoá?
      </div>
      <div class="flex flex-row space-x-2 items-center justify-center">
        <button mat-flat-button color="warn" mat-dialog-close="false">Huỷ</button>
        <button mat-flat-button color="primary" mat-dialog-close="true">Đồng Ý</button>
      </div>
    </div>
  </div>
</ng-template>