<section class="w-screen flex items-center bg-gray-200 py-3">
  <div class="container">
    <div class="flex items-center justify-center">
      <ol aria-label="Breadcrumb" class="flex items-center whitespace-nowrap min-w-0 gap-2">
        <li class="text-sm">
          <a class="flex items-center gap-2 align-middle text-default-800 transition-all leading-none hover:text-primary-500"
            href="javascript:void(0)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="home"
              class="lucide lucide-home w-4 h-4">
              <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
              <polyline points="9 22 9 12 15 12 15 22"></polyline>
            </svg>
            Trang Chủ
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              data-lucide="chevron-right" class="lucide lucide-chevron-right w-4 h-4">
              <path d="m9 18 6-6-6-6"></path>
            </svg>
          </a>
        </li>
        <li aria-current="page" class="text-sm font-medium text-primary truncate leading-none hover:text-primary-500">
          Thông Tin Cá Nhân
        </li>
      </ol>
    </div>
  </div>
</section>
<section class="bg-white">
  <div class="container py-8">
        <div class="grid lg:grid-cols-3 grid-cols-1 gap-4">
          <div class="grid grid-cols-1 lg:gap-8 gap-2">
            <div class="relative mx-auto">
              <app-avatar [Image]="User.Image" [width]="'w-full'" [height]="'h-full'" (UploadEmit)="GetUpload($event)"></app-avatar>
            </div>
            <!-- social account -->

            <!-- <div class="p-4 border rounded-lg lg:gap-8 gap-2">
              <div class="flow-root">
                <h3 class="text-xl font-bold">Social accounts</h3>
                <ul class="divide-y divide-gray-200">
                  <li class="py-4">
                    <div class="flex items-center space-x-4">
                      <div class="flex-shrink-0">
                        <svg class="w-5 h-5" aria-hidden="true" focusable="false" data-prefix="fab"
                          data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                          <path fill="currentColor"
                            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
                          </path>
                        </svg>
                      </div>
                      <div class="flex-1 min-w-0">
                        <span class="block text-base font-semibold text-gray-900 truncate">
                          Facebook account
                        </span>
                        <a href="#" class="block text-sm font-normal text-fuchsia-500 truncate hover:underline">
                          www.facebook.com/themesberg
                        </a>
                      </div>
                      <div class="inline-flex items-center">
                        <a href="#"
                          class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:scale-[1.02] transition-transform">Disconnect</a>
                      </div>
                    </div>
                  </li>
                  <li class="py-4">
                    <div class="flex items-center space-x-4">
                      <div class="flex-shrink-0">
                        <svg class="w-5 h-5" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
                          role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor"
                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
                          </path>
                        </svg>
                      </div>
                      <div class="flex-1 min-w-0">
                        <span class="block text-base font-semibold text-gray-900 truncate">
                          Twitter account
                        </span>
                        <a href="#" class="block text-sm font-normal text-fuchsia-500 truncate hover:underline">
                          www.twitter.com/themesberg
                        </a>
                      </div>
                      <div class="inline-flex items-center">
                        <a href="#"
                          class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-gray-900 bg-white rounded-lg border border-gray-300 hover:bg-gray-100 hover:scale-[1.02] transition-transform">Disconnect</a>
                      </div>
                    </div>
                  </li>
                  <li class="py-4">
                    <div class="flex items-center space-x-4">
                      <div class="flex-shrink-0">
                        <svg class="w-5 h-5" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github"
                          role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                          <path fill="currentColor"
                            d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z">
                          </path>
                        </svg>
                      </div>
                      <div class="flex-1 min-w-0">
                        <span class="block text-base font-semibold text-gray-900 truncate">
                          Github account
                        </span>
                        <span class="block text-sm font-normal text-gray-500 truncate">
                          Not connected
                        </span>
                      </div>
                      <div class="inline-flex items-center">
                        <a href="#"
                          class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-white bg-gradient-to-br from-pink-500 to-voilet-500 rounded-lg shadow-md shadow-gray-300 hover:scale-[1.02] transition-transform">Connect</a>
                      </div>
                    </div>
                  </li>
                  <li class="pt-4 pb-6">
                    <div class="flex items-center space-x-4">
                      <div class="flex-shrink-0">
                        <svg class="w-5 h-5" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="dribbble"
                          role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="currentColor"
                            d="M256 8C119.252 8 8 119.252 8 256s111.252 248 248 248 248-111.252 248-248S392.748 8 256 8zm163.97 114.366c29.503 36.046 47.369 81.957 47.835 131.955-6.984-1.477-77.018-15.682-147.502-6.818-5.752-14.041-11.181-26.393-18.617-41.614 78.321-31.977 113.818-77.482 118.284-83.523zM396.421 97.87c-3.81 5.427-35.697 48.286-111.021 76.519-34.712-63.776-73.185-116.168-79.04-124.008 67.176-16.193 137.966 1.27 190.061 47.489zm-230.48-33.25c5.585 7.659 43.438 60.116 78.537 122.509-99.087 26.313-186.36 25.934-195.834 25.809C62.38 147.205 106.678 92.573 165.941 64.62zM44.17 256.323c0-2.166.043-4.322.108-6.473 9.268.19 111.92 1.513 217.706-30.146 6.064 11.868 11.857 23.915 17.174 35.949-76.599 21.575-146.194 83.527-180.531 142.306C64.794 360.405 44.17 310.73 44.17 256.323zm81.807 167.113c22.127-45.233 82.178-103.622 167.579-132.756 29.74 77.283 42.039 142.053 45.189 160.638-68.112 29.013-150.015 21.053-212.768-27.882zm248.38 8.489c-2.171-12.886-13.446-74.897-41.152-151.033 66.38-10.626 124.7 6.768 131.947 9.055-9.442 58.941-43.273 109.844-90.795 141.978z">
                          </path>
                        </svg>
                      </div>
                      <div class="flex-1 min-w-0">
                        <span class="block text-base font-semibold text-gray-900 truncate">
                          Dribbble account
                        </span>
                        <span class="block text-sm font-normal text-gray-500 truncate">
                          Not connected
                        </span>
                      </div>
                      <div class="inline-flex items-center">
                        <a href="#"
                          class="py-2 px-3 mr-3 mb-3 text-sm font-medium text-center text-white bg-gradient-to-br from-pink-500 to-voilet-500 rounded-lg shadow-md shadow-gray-300 hover:scale-[1.02] transition-transform">Connect</a>
                      </div>
                    </div>
                  </li>
                </ul>
                <div>
                  <button
                    class="text-white bg-gradient-to-br from-pink-500 to-voilet-500 rounded-lg shadow-md shadow-gray-300 hover:scale-[1.02] transition-transform font-medium text-sm px-5 py-2.5 text-center"
                    fdprocessedid="u895i">
                    Save all
                  </button>
                </div>
              </div>
            </div> -->
         <!-- social account -->
          </div>
          <div class="col-span-2 grid grid-col-1 lg:gap-8 gap-2">
            <div class="grid lg:grid-cols-1 p-4 border rounded-lg gap-4">
              <div class="text-xl font-medium text-default-900 mb-4">Thông Tin Cá Nhân</div>
              <div>
                <label class="block text-sm font-medium text-default-900 mb-2">Họ Tên</label>
                <input [(ngModel)]="User.Hoten" [ngModelOptions]="{standalone: true}"
                  class="block w-full bg-transparent rounded-full py-2.5 px-4 border  focus:ring-transparent focus: dark:bg-default-50"
                  type="text" placeholder="Vui lòng nhập họ tên">
              </div>
              <div>
                <label class="block text-sm font-medium text-default-900 mb-2" for="userName">Số Điện Thoại</label>
                <input [(ngModel)]="User.SDT" [ngModelOptions]="{standalone: true}"
                  class="block w-full bg-transparent rounded-full py-2.5 px-4 border  focus:ring-transparent focus: dark:bg-default-50"
                  type="text" placeholder="Enter Your User Name">
              </div>

              <div>
                <label class="block text-sm font-medium text-default-900 mb-2" for="emailAddress">Email</label>
                <input [(ngModel)]="User.email" [ngModelOptions]="{standalone: true}"
                  class="block w-full bg-transparent rounded-full py-2.5 px-4 border  focus:ring-transparent focus: dark:bg-default-50"
                  type="email" placeholder="demoexample@mail.com">
              </div>
              <div>
                <label class="block text-sm font-medium text-default-900 mb-2">Địa Chỉ</label>
                <select id="country"
                  class="block w-full bg-transparent rounded-full py-2.5 px-4 border  focus:ring-transparent focus: dark:bg-default-50">
                  <option value="United States" selected="">United States</option>
                  <option value="Canada">Canada</option>
                  <option value="Australia">Australia</option>
                  <option value="Germany">Germany</option>
                  <option value="Bangladesh">Bangladesh</option>
                  <option value="China">China</option>
                  <option value="Argentina">Argentina</option>
                  <option value="Bharat">Bharat</option>
                  <option value="Afghanistan">Afghanistan</option>
                  <option value="France">France</option>
                  <option value="Brazil">Brazil</option>
                  <option value="Belgium">Belgium</option>
                  <option value="Colombia">Colombia</option>
                  <option value="Albania">Albania</option>
                </select>
              </div>
              <div>
                <button (click)="UpdateProfile()"
                  class="flex items-center justify-center gap-2 rounded-full bg-primary px-6 py-2.5 text-center text-sm font-semibold text-white shadow-sm transition-all duration-200 hover:border-primary-700 hover:bg-primary-500">
                  Lưu Thay Đổi</button>
              </div>
            </div>

            <div class="p-4 border rounded-lg">
              <div class="text-xl font-medium text-default-900 mb-4">Đổi Mật Khẩu</div>

              <div class="mb-4">
                <label class="block text-sm font-medium text-default-900 mb-2">Mật Khẩu Hiện Tại</label>
                <div class="flex">
                  <input type="password" 
                    class="form-password block w-full rounded-s-full py-2.5 px-4 bg-transparent border  focus:ring-transparent focus:"
                    placeholder="Vui lòng nhập mật khẩu hiện tại">
                  <button  class="password-toggle inline-flex items-center justify-center py-2.5 px-4 border rounded-e-full bg-transparent -ms-px ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      data-lucide="eye" class="lucide lucide-eye password-eye-on h-5 w-5 text-default-600">
                      <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      data-lucide="eye-off"
                      class="lucide lucide-eye-off password-eye-off h-5 w-5 text-default-600 hidden">
                      <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                      <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                      <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                      <line x1="2" x2="22" y1="2" y2="22"></line>
                    </svg>
                  </button>
                </div>
              </div>

              <div class="mb-4">
                <label class="block text-sm font-medium text-default-900 mb-2" for="new_password">Mật khẩu mới</label>
                <div class="flex">
                  <input type="password" id="new_password"
                    class="form-password block w-full rounded-s-full py-2.5 px-4 bg-transparent border  focus:ring-transparent focus:"
                    placeholder="Vui lòng nhập mật khẩu mới">
                  <button id="new_password"
                    class="password-toggle inline-flex items-center justify-center py-2.5 px-4 border rounded-e-full bg-transparent -ms-px ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      data-lucide="eye" class="lucide lucide-eye password-eye-on h-5 w-5 text-default-600">
                      <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      data-lucide="eye-off"
                      class="lucide lucide-eye-off password-eye-off h-5 w-5 text-default-600 hidden">
                      <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                      <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                      <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                      <line x1="2" x2="22" y1="2" y2="22"></line>
                    </svg>
                  </button>
                </div>
              </div>

              <div class="mb-4" data-x-password="">
                <label class="block text-sm font-medium text-default-900 mb-2" for="confirm_password">Xác nhận mật khẩu</label>
                <div class="flex">
                  <input type="password"   class="form-password block w-full rounded-s-full py-2.5 px-4 bg-transparent border  focus:ring-transparent focus:"
                    placeholder="Vui lòng nhập lại mật khẩu mới">
                  <button   class="password-toggle inline-flex items-center justify-center py-2.5 px-4 border rounded-e-full bg-transparent -ms-px ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      data-lucide="eye" class="lucide lucide-eye password-eye-on h-5 w-5 text-default-600">
                      <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      data-lucide="eye-off"
                      class="lucide lucide-eye-off password-eye-off h-5 w-5 text-default-600 hidden">
                      <path d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                      <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                      <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                      <line x1="2" x2="22" y1="2" y2="22"></line>
                    </svg>
                  </button>
                </div>
              </div>

              <div>
                <button
                  class="flex items-center justify-center gap-2 rounded-full border border-primary bg-primary px-6 py-2.5 text-center text-sm font-semibold text-white shadow-sm transition-all duration-200 hover:border-primary-700 hover:bg-primary-500">
                  Lưu thay đổi</button>
              </div>
            </div>
          </div>
        </div>
  </div>
</section>