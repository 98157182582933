<mat-drawer-container class="example-container w-full h-full " autosize>
  <mat-drawer #drawer mode="side" class="!flex flex-col flex-auto min-w-0 relative h-full w-full" position="end">
    <div class="relative flex flex-col h-full w-full overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer>
  <mat-drawer-content class="!flex flex-col flex-auto min-w-0 relative !h-[90vh]">
    <div class="relative flex flex-col">
      <div class="flex justify-between items-center space-x-2 bg-white p-2 rounded-lg">
        <div class="flex flex-row space-x-3 justify-center items-center">
          <div class="relative mt-1 lg:w-80">
            <input (keyup)="applyFilter($event)" type="text" name="email" id="topbar-search"
              class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-2 focus:ring-fuchsia-50 focus:border-fuchsia-300 block w-full p-2.5"
              placeholder="Tìm Kiếm">
          </div>
          <div class="hidden lg:flex flex-row space-x-3 cursor-pointer">
            <div (click)="writeExcelFile()" class="flex flex-col items-center p-2 hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_download</span>
              <span class="text-xs">Download</span>
            </div>
            <div (click)="uploadfile.click()" class="flex flex-col items-center p-2 hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Upload File">file_upload</span>
              <span class="text-xs">Upload</span>
              <input class="hidden" (change)="readExcelFile($event)" type="file" #uploadfile>
            </div>
            <div (click)="LoadDrive()"
              class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Drive">cloud_download</span>
              <span class="text-xs">Drive ({{0}})</span>
            </div>

            <!-- @if(SanphamsDrive.length>0)
                {
                  <div (click)="SyncDrive()"
                  class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
                  <span class="material-symbols-outlined text-xl" matTooltip="Drive">cloud_sync</span>
                  <span class="text-xs">Sync ({{FilterLists.length||0}}/{{SanphamsDrive.length||0}})</span>
                </div>
                } -->

            <div (click)="openDialogDanhmuc(dialogDanhmuc)"
              class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Drive">create_new_folder</span>
              <span class="text-xs">Thêm Danh Mục</span>
            </div>

            <div (click)="openDialog(dialogTemplate)"
              class="flex flex-col items-center p-2 text-center justify-center hover:bg-gray-200 rounded-lg">
              <span class="material-symbols-outlined text-xl" matTooltip="Drive">post_add</span>
              <span class="text-xs">Thêm Bài Viết</span>
            </div>

          </div>
        </div>
        <!-- <div class="flex flex-row space-x-2 items-center">
          <button mat-flat-button color="primary">
            <div (click)="openDialog(dialogTemplate)" class="flex flex-row items-center">
              <span class="material-symbols-outlined">add</span>
              <span class="hidden lg:flex">Bài viết</span>
            </div>
          </button>
        </div> -->
      </div>
      <!-- <div class="flex justify-between items-center space-x-2 bg-white p-2 rounded-lg w-full">
        <div class="">
          <mat-chip-listbox>
            @for (item of TypeArticle; track $index) {
            <mat-chip-option [selected]="item.Select" (click)="ChoseType(item,$index)">{{item.Title}}</mat-chip-option>
            }
            <mat-chip-option>One fish</mat-chip-option>
          <mat-chip-option>Two fish</mat-chip-option>
          <mat-chip-option color="accent" selected>Accent fish</mat-chip-option>
          <mat-chip-option color="warn">Warn fish</mat-chip-option>
          </mat-chip-listbox>
        </div>

      </div> -->

    </div>
    <div class="relative grid grid-cols-4 h-full w-full overflow-auto">
      <div>
        <mat-tree class="my-6 mx-4 p-4 rounded-lg shadow-xl shadow-gray-200" [dataSource]="treedataSource"
          [treeControl]="treeControl">
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <div (click)="ChoosenDanhmuc(node)"
              class="{{SelectDanhmuc.id==node.id?'bg-slate-200':''}} w-full flex flex-row space-x-2 items-center p-2 hover:bg-slate-100 rounded-lg">
              <span>
                @if(node && node.Image&&node.Image.Thumb)
                {
                <img loading="lazy" src="assets/images/sanpham/{{node.Image.Thumb}}" class="w-20 mx-auto">
                }
                @else if(node && node.Image&&node.Image.Hinhchinh&&node.Image.Hinhchinh.src){
                <img loading="lazy" src="{{node.Image.Hinhchinh.src}}" class="w-20 mx-auto">
                }
                @else {
                <img loading="lazy" src="assets/images/noimage.png" class="p-3 max-w-20 mx-auto rounded-lg">
                }
              </span>
              <div class="flex flex-col space-y-2">
                <span>
                  {{node.Title}}
                </span>
                <a [href]="'blog/'+node.Slug">
                  {{node.Slug}}
                </a>
              </div>
              <span class="text-red-500">({{node.Soluong||0}})</span>
            </div>
          </mat-tree-node>
        </mat-tree>
      </div>
      <div class="col-span-3 flex flex-col space-y-3">
        <div class="flex flex-col my-6 mx-4 rounded-lg shadow-xl shadow-gray-200">
          <div class="border rounded-lg">
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="Hinhanh">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Hình Ảnh </th>
                <td mat-cell *matCellDef="let item">
                  <a class="text-base font-semibold text-gray-900" routerLink="{{item.id}}">
                    @if(item?.Image?.Thumb)
                    {
                    <img loading="lazy" src="assets/images/baiviet/{{item?.Image?.Thumb}}" class="p-3 max-w-32 mx-auto rounded-lg">
                    }
                    @else if(item?.Image?.Hinhchinh?.src){
                      <img loading="lazy" src="{{item?.Image?.Hinhchinh?.src}}" class="w-20 mx-auto">
                      }
                    @else {
                    <img loading="lazy" src="assets/images/noimage.png" class="p-3 max-w-32 mx-auto rounded-lg">
                    }

                    <!-- @if(node && node.Image&&node.Image.Thumb)
                    {
                    <img loading="lazy" src="assets/images/sanpham/{{node.Image.Thumb}}" class="w-20 mx-auto">
                    }
                    @else if(node && node.Image&&node.Image.Hinhchinh&&node.Image.Hinhchinh.src){
                    <img loading="lazy" src="{{node.Image.Hinhchinh.src}}" class="w-20 mx-auto">
                    }
                    @else {
                    <img loading="lazy" src="assets/images/noimage.png" class="p-3 max-w-20 mx-auto rounded-lg">
                    } -->

                  </a>
              </ng-container>
              <ng-container matColumnDef="Title">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tiêu Đề </th>
                <td mat-cell *matCellDef="let item">
                  <div class="cursor-pointer flex flex-col space-y-2">
                  <div class="flex flex-row space-x-2 items-center">
                    <div class="text-base font-semibold text-gray-900">{{item.Title}}</div>
                    <span class="material-symbols-outlined" [cdkCopyToClipboard]="BaseURL+':'+PortURL+'/blog/'+item?.Danhmuc?.Slug+'/'+item?.Slug">
                      content_copy
                    </span> 
                  </div>

                   <a href="/blog/{{item?.Danhmuc?.Slug}}/{{item?.Slug}}" class="text-sm font-normal text-gray-500">/blog/{{item?.Danhmuc?.Slug}}/{{item?.Slug}}</a>

                </div> 
                </td>
              </ng-container>
              <ng-container matColumnDef="Noibat">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nổi Bật </th>
                <td mat-cell *matCellDef="let item">
                  <div (click)="ChangeStatus(item,'Noibat')">
                    @if (item.Noibat==1) {
                      <span class="material-symbols-outlined text-blue-500">check_circle</span>
                     }
                    @else { <span class="material-symbols-outlined text-red-500">cancel</span> }
                  </div> 
                </td>
              </ng-container>
              <ng-container matColumnDef="Trangthai">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Trạng Thái </th>
                <td mat-cell *matCellDef="let item">

                  <div (click)="ChangeStatus(item,'Status')">
                    @if (item.Status==1) {
                      <span class="material-symbols-outlined text-blue-500">check_circle</span>
                     }
                    @else { <span class="material-symbols-outlined text-red-500">cancel</span> }
                  </div> 
                  
                </td>
              </ng-container>
              <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Hành Động </th>
                <td mat-cell *matCellDef="let row">
                  <div class="flex flex-row space-x-2 items-center">
                    <a routerLink="{{row.id}}"  class="material-symbols-outlined p-2 border rounded-full 
                    bg-slate-200 text-blue-500 
                    hover:bg-blue-500 hover:text-white">
                      edit_square
                    </a>
                    <span class="material-symbols-outlined p-2 border rounded-full 
                    bg-slate-200 text-red-500 
                    hover:bg-red-500 hover:text-white">
                      delete
                    </span>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell p-4 text-red-500" colspan="4">Chưa Có Bài Viết Nào</td>
              </tr>
            </table>
          </div>



          <!-- <div class="overflow-x-auto rounded-2xl">
            <div class="inline-block min-w-full align-middle">
              <div class="overflow-hidden">
                <table class="min-w-full divide-y divide-gray-200 table-fixed">
                  <thead class="bg-white">
                    <tr>
                      <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                        Hình ảnh
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                        Tên Bài Viết
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                        Danh Mục
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                        Trạng Thái
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                        Bán Chạy
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                        Nổi Bật
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                        Mới
                      </th>
                      <th scope="col" class="p-4 text-xs font-medium text-left text-gray-500 uppercase lg:p-5">
                        Hành Động
                      </th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    @for (item of FilterLists; track $index) {
                    <tr class="hover:bg-gray-100">
                      <td class="text-sm font-normal text-gray-500 whitespace-nowrap">
                        <div class="text-base font-semibold text-gray-900">
                          @if(item.Image.Thumb)
                          {
                          <img loading="lazy" src="assets/images/baiviet/{{item.Image.Thumb}}" class="p-3 max-w-32 mx-auto rounded-lg">
                          }
                          @else {
                          <img loading="lazy" src="assets/images/noimage.png" class="p-3 max-w-32 mx-auto rounded-lg">
                          }
                        </div>
                      </td>
                      <td class="p-4 text-sm font-normal text-gray-500 whitespace-normal lg:p-5 truncate">
                        <a href="admin/baiviet/{{item.id}}">
                          <div class="text-base font-semibold text-gray-900">{{item.Title}}</div>
                          <div class="text-sm font-normal text-gray-500">{{item.Slug}}</div>
                        </a>
                      </td>
                      <td class="p-4 text-sm font-normal text-gray-500 whitespace-nowrap lg:p-5">
                        <div class="text-base font-semibold text-gray-900">{{item.id_cat}}</div>
                      </td>
                      <td class="p-4 text-base font-medium text-gray-900 whitespace-nowrap lg:p-5">
                        @if (item.Status==1) {
                        <span class="material-symbols-outlined text-blue-500">check_circle</span>
                        }
                        @else { <span class="material-symbols-outlined text-red-500">cancel</span> }
                      </td>
                      <td class="p-4 text-base font-medium text-gray-900 whitespace-nowrap lg:p-5">
                        @if (item.Banchay==0) {
                        <span class="material-symbols-outlined text-blue-500">check_circle</span>
                        }
                        @else { <span class="material-symbols-outlined text-red-500">cancel</span> }
                      </td>
                      <td class="p-4 text-base font-medium text-gray-900 whitespace-nowrap lg:p-5">
                        @if (item.Noibat==0) {
                        <span class="material-symbols-outlined text-blue-500">check_circle</span>
                        }
                        @else { <span class="material-symbols-outlined text-red-500">cancel</span> }
                      </td>
                      <td class="p-4 text-base font-medium text-gray-900 whitespace-nowrap lg:p-5">
                        @if (item.Moi==0) {
                        <span class="material-symbols-outlined text-blue-500">check_circle</span>
                        }
                        @else { <span class="material-symbols-outlined text-red-500">cancel</span> }
                      </td>
                      <td class="p-4 space-x-2 whitespace-nowrap lg:p-5">
                        <a routerLink="{{item.id}}" data-modal-toggle="product-modal"
                          class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 hover:text-gray-900 hover:scale-[1.02] transition-all">
                          <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z">
                            </path>
                            <path fill-rule="evenodd"
                              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                              clip-rule="evenodd"></path>
                          </svg>
                          Chỉnh Sửa
                        </a>
                        <button type="button" data-modal-toggle="delete-product-modal"
                          class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-gradient-to-br from-red-400 to-red-600 rounded-lg shadow-md shadow-gray-300 hover:scale-[1.02] transition-transform">
                          <svg class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                              clip-rule="evenodd"></path>
                          </svg>
                          Xoá
                        </button>
                      </td>
                    </tr>
                    }

                  </tbody>
                </table>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="flex justify-between items-center mx-4 mt-4 mb-6 bg-white p-3 rounded-lg">
          <mat-paginator class="w-full" [pageSizeOptions]="[10, 20, 50]" [pageSize]="SearchParams.pageSize"
            [length]="this.Lists.totalCount" [pageIndex]="SearchParams.pageNumber" (page)="onPageChange($event)">
          </mat-paginator>
        </div> -->
      </div>
    </div>

  </mat-drawer-content>
</mat-drawer-container>

<ng-template #dialogTemplate>
  <div class="lg:w-[40vw] p-2 flex flex-col space-y-3">
    <div mat-dialog-title>Thêm Mới</div>
    <div mat-dialog-content>
      <div class="flex flex-col w-full space-y-4 lg:p-4">
        <mat-form-field appearance="outline">
          <mat-label>Danh Mục</mat-label>
          <mat-select [(ngModel)]="Detail.idDM" [ngModelOptions]="{standalone: true}">
            <mat-option [value]="item.id" *ngFor="let item of ListDanhmuc">{{item.Title}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Tiêu Đề</mat-label>
          <input matInput [(ngModel)]="Detail.Title" [ngModelOptions]="{standalone: true}" placeholder="Tiêu Đề"
            (input)="FillSlug()">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Slug</mat-label>
          <input matInput [(ngModel)]="Detail.Slug" [ngModelOptions]="{standalone: true}" placeholder="Slug">
        </mat-form-field>
      </div>
      <div class="flex flex-row space-x-2 items-center justify-center">
        <button mat-flat-button color="warn" mat-dialog-close="false">Huỷ</button>
        <button mat-flat-button color="primary" mat-dialog-close="true">Đồng Ý</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #dialogDanhmuc>
  <div class="lg:w-[40vw] p-2 flex flex-col space-y-3">
    <div mat-dialog-title>Thêm Mới Danh Mục</div>
    <div mat-dialog-content>
      <div class="flex flex-col w-full space-y-4 lg:p-4">
        <mat-form-field appearance="outline">
          <mat-label>Danh Mục Cha</mat-label>
          <mat-select [(ngModel)]="Danhmuc.pid" [ngModelOptions]="{standalone: true}">
            <mat-option [value]="item" *ngFor="let item of ListDanhmuc">{{item.Title}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Tiêu Đề</mat-label>
          <input matInput [(ngModel)]="Danhmuc.Title" [ngModelOptions]="{standalone: true}" placeholder="Tiêu Đề"
            (input)="FillSlugDanhmuc()">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Slug</mat-label>
          <input matInput [(ngModel)]="Danhmuc.Slug" [ngModelOptions]="{standalone: true}" placeholder="Slug">
        </mat-form-field>
      </div>
      <div class="flex flex-row space-x-2 items-center justify-center">
        <button mat-flat-button color="warn" mat-dialog-close="false">Huỷ</button>
        <button mat-flat-button color="primary" mat-dialog-close="true">Đồng Ý</button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #XoaTeamplate>
  <div class="lg:w-[40vw] p-2 flex flex-col space-y-3">
    <div mat-dialog-title>Xoá</div>
    <div mat-dialog-content>
      <div class="flex flex-col w-full space-y-4 lg:p-4">
        Bạn chắc chắn muốn Xoá?
      </div>
      <div class="flex flex-row space-x-2 items-center justify-center">
        <button mat-flat-button color="warn" mat-dialog-close="false">Huỷ</button>
        <button mat-flat-button color="primary" mat-dialog-close="true">Đồng Ý</button>
      </div>
    </div>
  </div>
</ng-template>