<div class="bg-gray-50 relative p-4 w-full h-full md:h-auto">
  <div class="relative bg-white rounded-2xl  shadow-gray-700">
    <div class="flex justify-between items-center p-5 rounded-t border-b">
      <a [routerLink]="['../']" (click)="CloseDrawer()">
        <span class="material-symbols-outlined ">
          close
        </span>
      </a>
      <span class="lg:text-xl text-xs font-semibold">
        {{Detail?.Title}}
      </span>
      <div class="flex flex-row space-x-2 cursor-pointer">
    </div>
    </div>
    <div class="p-4 grid grid-cols-1 gap-4">
      @if(Detail.Slug=="cau-hinh-email"){
        <app-cauhinhemail [Dulieu]="Detail.Dulieu" (UploadDulieu)="GetDulieu($event)"></app-cauhinhemail>
      }
      @if(Detail.Slug=="footer"){
       <app-cauhinhfooter [Dulieu]="Detail.Dulieu" (UploadDulieu)="GetDulieu($event)"></app-cauhinhfooter>
      }
    </div>
  </div>
</div>