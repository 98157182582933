<div class="relative lg:bg-[#57A345] bg-white">
    <div class="lg:block hidden w-full h-full">
        <div class="h-full grid grid-cols-11">
            <div class="bg-white my-1"></div>
            <a href="/" class="col-span-3 p-2 flex items-center justify-end pe-10 me-4 rounded-r-full bg-white my-1">
                <!-- <img loading="lazy" src="/assets/images/logo.webp" class="lg:h-12 h-10"> -->
                <img loading="lazy" src="/assets/images/logo-full.png" class="max-h-20">

            </a>
            <div class="col-span-5 flex flex-col space-y-2 py-2 cursor-pointer justify-center">
                <div class="flex flex-row space-x-16 items-center">
                    <div class="hidden lg:flex flex-row space-x-3 text-white">
                        @for(item of Menus; track item.id) {
                        @if (item.children!=null&&item.children.length>0) {
                        <div class="group relative">
                            <a [href]="[item.Slug]"
                                class="flex flex-row items-center header-link py-2 font-semibold uppercase transition-all">
                                {{item.Title}}
                                <span class="material-symbols-outlined">
                                    expand_more
                                </span>
                            </a>
                            @if (item.id==2) {
                            <div class="absolute w-[50vw] text-black z-10 hidden group-hover:grid border rounded-lg grid-cols-3 gap-4 bg-white p-4"
                                (mouseleave)="Menus[$index].Show=false">
                                @for (item of item.children; track $index) {
                                <div class="flex flex-row space-x-2 items-center">
                                    @if(item && item.Image&&item.Image.Thumb)
                                    {
                                    <img loading="lazy" src="assets/images/sanpham/{{item.Image.Thumb}}" class="h-10">
                                    }
                                    @else if(item && item.Image&&item.Image.Hinhchinh&&item.Image.Hinhchinh.src){
                                    <img loading="lazy" src="{{item.Image.Hinhchinh.src}}" class="h-10">
                                    }
                                    @else {
                                    <img loading="lazy" src="assets/images/noimage.png" class="p-3 max-h-10 rounded-lg">
                                    }
                                    <!-- <img loading="lazy" src="/assets/images/sanpham/rau-lang26385834.PNG" class="h-10"> -->
                                    <a href="danh-muc/{{item.Slug}}">{{item.Title}}</a>
                                </div>
                                }
                            </div>
                            }
                            @else {
                            <div class="border rounded-lg grid grid-cols-1 gap-4 bg-white p-4"
                                (mouseleave)="Menus[$index].Show=false">
                                @for (item of item.children; track $index) {
                                <a href="{{item.Slug}}" class="">{{item.Title}}</a>
                                }
                            </div>
                            }
                        </div>
                        <!-- <mat-menu #beforeMenu="matMenu" xPosition="before" class="w-screen">
                <div class="w-screen grid grid-cols-4" (mouseleave)="menuTrigger.closeMenu()">
                    <a [routerLink]="[item1.Slug]" routerLinkActive="!bg-black !text-white" mat-menu-item
                        *ngFor="let item1 of item.children">{{item1.Title}}
                    </a>
                </div>
            </mat-menu> -->
                        }
                        @else {
                        <a class="flex flex-row items-center header-link py-2 font-semibold uppercase transition-all"
                            [href]="[item.Slug]">
                            <span class="text-sm border-r px-2">
                                {{item.Title}}
                            </span>
                        </a> }
                        }
                    </div>
                </div>
                <div class="flex flex-row space-x-2 items-center w-full">
                    <span class="material-symbols-outlined font-bold text-[#FAA61A] !2text-lg rounded-full border-2 border-[#FAA61A] p-2">call</span>
                    <a href="tel:0865770009" class="text-[#FAA61A] font-bold text-lg">0865.77.0009</a>
                    <div class="relative w-1/2" (click)="TimkiemDialog(dialogTimkiem)">
                        <input
                            class="hidden lg:block w-full py-2 px-4 pe-10 text-sm 
                text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Tìm Kiếm" />
                        <div class="lg:absolute inset-y-0 end-0 flex items-center lg:pe-3 cursor-pointer">
                            <button mat-icon-button>
                                <span class="material-symbols-outlined lg:text-gray-400 text-white">
                                    search
                                </span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-span-2 flex flex-col space-y-3 py-2 justify-center">
                <div class="justify-start items-center">
                    @if(this.Token){
                    <div class="flex flex-row space-x-2 items-center cursor-pointer text-white">
                        <a routerLink="/profile">
                            <img loading="lazy" [src]="User?.Image?.Main||'/assets/images/noimage.png'"
                            class="rounded-full w-10 h-10 border">
                        </a>
                        <a routerLink="/admin" class="underline">
                            <span>Admin</span>
                        </a>
                        <a (click)="Dangxuat()" class="underline">
                                <span>Đăng Xuất</span>
                        </a>
                    </div>

                    }
                    @else {

                    <div class="hidden lg:flex flex-row space-x-2 items-center text-white">
                        <a href="dangnhap" class="px-2 underline">
                            Đăng Nhập
                        </a>
                        <!-- <a href="dangky">
                                Đăng Ký
                            </a> -->
                    </div>
                    <div class="lg:hidden">
                        <a href="dangnhap" mat-icon-button>
                            <span class="material-symbols-outlined text-white">
                                person
                            </span>
                        </a>
                    </div>
                    }
                    <mat-menu #user="matMenu" xPosition="before">
                        <button mat-menu-item>
                            <a routerLink="/profile" routerLinkActive="bg-gray-200"
                                class="flex flex-row space-x-3 items-center">
                                <span class="material-symbols-outlined">manage_accounts</span>
                                <span>Tài Khoản</span>
                            </a>
                        </button>
                        <button mat-menu-item>
                            <a routerLink="/admin" routerLinkActive="bg-gray-200"
                                class="flex flex-row space-x-3 items-center">
                                <span class="material-symbols-outlined">account_circle</span>
                                <span>Admin</span>
                            </a>
                        </button>
                        <a (click)="Dangxuat()" mat-menu-item>
                            <div class="flex flex-row space-x-3 items-center">
                                <span class="material-symbols-outlined">logout</span>
                                <span>Đăng Xuất</span>
                            </div>
                        </a>
                    </mat-menu>
                </div>



                <div class="flex flex-row space-x-1 items-center w-full">
                    <div class="relative">
                        <a href="gio-hang" mat-icon-button matBadgeColor="warn">
                            <span class="material-symbols-outlined text-white">shopping_cart</span>
                        </a>
                        <span
                            class="absolute font-bold -top-1 right-0 bg-white text-red-500 text-xs px-2 py-1 rounded-full">
                            {{(Soluong| number:'1.0-0')||0}}
                        </span>
                    </div>
                    <a href="gio-hang" class="text-white">
                        {{(Tongcong | number:'1.0-0')||'0'}}đ
                    </a>
                </div>
                <div class="lg:hidden">
                    <button mat-icon-button (click)="sidebarVisible = true">
                        <span class="material-symbols-outlined text-white">
                            menu
                        </span>
                    </button>
                </div>


            </div>
        </div>
    </div>

    <div class="lg:hidden flex flex-col w-full h-full">
        <div class="w-full flex flex-row justify-between items-center px-4">
            <button mat-icon-button (click)="sidebarVisible = true">
                <span class="material-symbols-outlined text-[#E1232A]">
                    menu
                </span>
            </button>
            <a href="/" class="p-2">
                <img loading="lazy" src="/assets/images/logo-full.png" class="max-h-12">
            </a>
            <div class="flex flex-row space-x-2 items-center justify-center">
                <!-- <div class="flex flex-row space-x-16 items-center justify-center">
                    @if(this.Token){
                    <img loading="lazy" [src]="User?.Image?.Main||'/assets/images/noimage.png'"
                        class="rounded-full w-10 h-10 border" [matMenuTriggerFor]="user">
                    }
                    @else {

                    <div class="hidden lg:flex flex-row space-x-2 text-xs items-center !text-[#E1232A]">
                        <a href="dangnhap" class="border-r px-2">
                            Đăng Nhập
                        </a>
                        <a href="dangky">
                            Đăng Ký
                        </a>
                    </div>
                    <div class="lg:hidden">
                        <a href="dangnhap" mat-icon-button>
                            <span class="material-symbols-outlined !text-[#E1232A]">
                                person
                            </span>
                        </a>
                    </div>
                    }
                </div> -->
                <div class="relative">
                    <a href="gio-hang" mat-icon-button matBadgeColor="warn">
                        <span class="material-symbols-outlined !text-[#E1232A]">shopping_cart</span>
                    </a>
                    <span
                        class="absolute font-bold -top-1 right-0 !bg-[#E1232A] text-white text-xs px-2 py-1 rounded-full">
                        {{(Soluong| number:'1.0-0')||0}}
                    </span>
                </div>
                <a href="gio-hang" class="!text-[#E1232A]">
                    {{(Tongcong | number:'1.0-0')||'0'}}đ
                </a>
            </div>
        </div>
        <!-- <div class="cursor-pointer flex flex-col space-y-2 justify-center items-center">
            <a class="flex flex-row items-center space-x-2 text-[#FAA61A]" href="tel:0865770009">
                <span class="material-symbols-outlined">smartphone</span>
                <span> Điện Thoại: 0865.77.0009</span>
            </a>
            //
            <a href="email:rausachtrangia@gmail.com" class="flex flex-row items-center space-x-2 text-[#FAA61A]" href="tel:0865770009">
                <span class="material-symbols-outlined">mail</span>
                <span>rausachtrangia&#64;gmail.com</span>
            </a>
            <div class="relative w-1/2" (click)="TimkiemDialog(dialogTimkiem)">
                <input
                    class="w-full py-2 px-4 pe-10 text-sm 
        text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Tìm Kiếm" />
                <div class="absolute inset-y-0 end-0 flex items-center lg:pe-3 cursor-pointer">
                    <button mat-icon-button>
                        <span class="material-symbols-outlined text-gray-400">
                            search
                        </span>
                    </button>
                </div>
            </div>
        </div> -->

        <div class="flex flex-row space-x-2 items-center justify-between w-full bg-[#57A345] p-2">
            <span class="material-symbols-outlined font-bold text-[#FAA61A] !2text-lg rounded-full border-2 border-[#FAA61A] p-2">call</span>
            <a href="tel:0865770009" class="text-[#FAA61A] font-bold text-lg">0865.77.0009</a>
            <div class="relative w-1/2" (click)="TimkiemDialog(dialogTimkiem)">
                <input
                    class="w-full py-2 px-4 pe-10 text-sm 
        text-gray-900 border border-gray-300 rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Tìm Kiếm" />
                <div class="absolute inset-y-0 end-0 flex items-center lg:pe-3 cursor-pointer">
                    <button mat-icon-button>
                        <span class="material-symbols-outlined text-gray-400">
                            search
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div class="cursor-pointer flex flex-row space-x-4 font-bold text-[#E1232A] items-center justify-center bg-gray-100 py-2">
           @if(this.Token){
            <div (click)="Dangxuat()">Đăng Xuất</div>
           }
           @else{
            <a href="dangnhap">Đăng Nhập</a>
            <span>|</span>
            <a href="dangky">Đăng Ký</a>
           }
            <span>|</span>
            <a href="gio-hang">Giỏ Hàng</a>
        </div>
        <!-- <div class="relative w-2/3 mx-auto" (click)="TimkiemDialog(dialogTimkiem)">
            <input class="w-full py-2 px-4 pe-10 text-sm bg-gray-100
    text-gray-900 border border-gray-300 rounded-full" placeholder="Tìm Kiếm" />
            <div class="absolute inset-y-0 end-0 flex items-center lg:pe-3 cursor-pointer">
                <button mat-icon-button>
                    <span class="material-symbols-outlined text-gray-400">
                        search
                    </span>
                </button>
            </div>

        </div> -->
    </div>






    <p-sidebar [(visible)]="sidebarVisible" class="z-10" position="left" class="w-1/2">
        <ng-template pTemplate="header">
            <a href="/">
                <img loading="lazy" src="/assets/images/logo.webp" class="lg:h-20 h-10">
            </a>
        </ng-template>
        <ng-template pTemplate="content">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                    <button mat-icon-button disabled></button>
                    <a href="{{node.Slug}}">{{node.Title}}</a>
                </mat-tree-node>
                <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.Title">
                        <span
                            class="material-symbols-outlined">{{treeControl.isExpanded(node)?'expand_more':'navigate_next'}}</span>
                    </button>
                    <a href="{{node.Slug}}">{{node.Title}}</a>
                </mat-tree-node>
            </mat-tree>
            <div>
                @if(this.Token){
                <div class="w-full flex flex-row justify-between">
                    <div class="flex flex-row space-x-2 items-center">
                        <img loading="lazy" [src]="User?.Image?.Main||'/assets/images/noimage.png'"
                            class="rounded-full w-10 h-10 border">
                        <span class="font-bold">{{User.Hoten||"Guest"}}</span>
                    </div>

                    <a (click)="Dangxuat()">
                        <button mat-icon-button>
                            <span class="material-symbols-outlined">
                                logout
                            </span>

                        </button>
                    </a>


                </div>
                }
                @else {
                <div class="flex flex-row space-x-2 items-center">
                    <a href="dangnhap" class="p-2 border rounded-full 
                                bg-slate-200 text-blue-500 
                                hover:bg-blue-500 hover:text-white">
                        Đăng Nhập
                    </a>
                    <a href="dangky" class="p-2 border rounded-full 
                          bg-slate-200 text-blue-500 
                          hover:bg-blue-500 hover:text-white">
                        Đăng Ký
                    </a>
                </div>
                }
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div class="text-pgray-400 text-sm text-center">
                <p>© Copyright {{Today|date:'yyyy'}}</p>
            </div>
        </ng-template>
    </p-sidebar>
    <ng-template #dialogTimkiem>
        <div
            class="relative lg:max-h-[90vh] lg:w-[50vw] max-h-[90vh] w-[90vw] flex flex-col justify-between space-y-2 lg:p-8 p-3 divide-y">
            <div class="relative flex flex-row space-x-2 items-center">
                <mat-form-field appearance="outline" subscriptSizing="dynamic" class="w-full">
                    <input matInput (input)="DoSearch($event)" placeholder="Tìm Kiếm">
                    <span matPrefix class="p-2 material-symbols-outlined">search</span>
                </mat-form-field>
                <div mat-dialog-actions>
                    <button class="ml-auto" mat-flat-button color="primary" mat-dialog-close="false">Đóng</button>
                </div>
            </div>
            <div class="relative flex flex-col space-y-2 h-full w-full overflow-auto p-3">
                @if(Timkiems.length>0){
                <div class="grid lg:grid-cols-4 grid-cols-2 justify-between">
                    @for (item of Timkiems; track $index) {

                    <app-sanphamblock [Detail]="item" [isMuangay]="false"></app-sanphamblock>

                    <!-- <div class="flex flex-col space-y-2">
                        <a href="san-pham/{{item.Slug}}" class="z-10 grid grid-cols-1 border rounded-lg p-3 bg-white">
                            @if(item && item.Image&&item.Image.Main)
                            {
                            <img loading="lazy" src="assets/images/sanpham/{{item.Image.Main}}" class="w-full">
                            }
                            @else if(item && item.Image&&item.Image.Hinhchinh&&item.Image.Hinhchinh.src){
                            <img loading="lazy" src="{{item.Image.Hinhchinh.src}}" class="w-full">
                            }
                            @else if(item && item.Image&&item.Image.Hinhchinh&&item.Image.Hinhchinh.src){
                            <img loading="lazy" src="assets/images/noimage.png" class="p-3 w-full mx-auto rounded-lg">
                            }
                            <div (click)="AddtoCart(item);$event.preventDefault()"
                                class="z-20 flex flex-row space-x-2 justify-end items-end">
                                <span
                                    class="z-10 material-symbols-outlined rounded-lg p-2 bg-[#57A345] text-white hover:bg-[#afe8a2]">shopping_cart</span>
                            </div>
                        </a>
                        <div class="flex flex-col justify-center">
                            <span class="text-base text-center font-bold line-clamp-1">{{item.Title}}</span>
                            <p class="text-gray-400 text-[13px] line-clamp-2" [innerHTML]="item.Mota"></p>
                            <div class="flex flex-row justify-center">
                                <div class="relative p-4">
                                    <img loading="lazy" src="assets/images/pricetag.svg" class="h-8">
                                    <div
                                        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-row">
                                        <span class="text-white font-bold"> {{item?.Giagoc[0]?.gia|
                                            number:'1.0-0'}}đ/{{item?.Giagoc[0]?.khoiluong}}{{item?.Giagoc[0]?.dvt}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    }

                </div>
                }
                @else{

                <span class="text-center font-bold">Không tìm thấy kết quả</span>
                }
            </div>
            <!-- <div mat-dialog-actions class="absolute top-0 right-0">
                <button class="ml-auto" mat-flat-button color="primary" mat-dialog-close="false">x</button>
            </div> -->
        </div>

        <!-- <div class="lg:w-[40vw] lg:h-[40vh] h-[95vh] flex flex-col justify-between space-y-3 divide-x-2">
        <div mat-dialog-content>
            <div class="flex flex-col w-full space-y-4 p-2">
                <mat-form-field appearance="outline" class="w-full !text-xs">
                    <input matInput (input)="DoSearch($event)"  placeholder="Tìm Kiếm">
                    <span matPrefix class="p-2 material-symbols-outlined">search</span>
                </mat-form-field>
                <div class="">
                    @for (item of Timkiems; track $index) {
                        <div class="p-2 rounded-lg bg-slate-50">
                            <span>{{item.Title}}</span>
                        </div> 
                    }
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="flex items-end">
            <button class="ml-auto" mat-flat-button color="primary"  mat-dialog-close="false">Đóng</button>
        </div>
    </div> -->
    </ng-template>
</div>